import { Injectable } from '@angular/core';
import { appConfig } from '@wss/config/wss-app-constants';
import { DatastoreService } from '@wss/service/datastore.service';
import { HttpService } from '@wss/service/http.service';
import { MessageService } from '@wss/service/message.service';
import { RequestService } from '@wss/service/request.service';
import { catchError, map } from 'rxjs/operators';
import { AccoutSummaryService } from "../../../account-summary-widget/src/services/account-summary.service";
import { calculateDaysLeft } from '@wss/common/util/util';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EndOfContractService {
  
  isBalloonRefinanceBannerClosed: boolean = false;

  constructor(
    private httpService: HttpService,
    private messageService: MessageService,
    private datastoreService: DatastoreService,
    private requestService: RequestService,
    private accountsService: AccoutSummaryService
    ) { }

  /*
  * Function to return date in format MM/dd/YYYY
  * Argument dateInMMddYYYY expected in format dd/MM/YYYY
  */
  getFormattedDate(dateInddMMYYYY: string) {
    let dateAfterDilimiter = dateInddMMYYYY.split("/");
    return dateAfterDilimiter[1] + "/" + dateAfterDilimiter[0] + "/" + dateAfterDilimiter[2];
  }

  /*
  * Function to return difference between dates in format MM/dd/YYYY
  * Arguments: date1: agreement completion date, date2: todays date
  */
  getDifferenceBetweenDates(date1: any, date2: any = new Date()) {
    let differenceInTime = Math.abs((new Date(date1).getTime() - new Date(date2).getTime()));
    return Math.floor(differenceInTime/ (1000 * 3600 * 24))
  }

  /*
  * Function to return weather contract is within 90 days
  * 
  */
  isContractWithinNintyDays() {
    let accountDetails = this.datastoreService.getAccountDetails();
    let todaysDate = new Date();
    if(accountDetails.final_payment_date && accountDetails.earliest_contact_date 
      && (todaysDate.getTime() < new Date(this.getFormattedDate(accountDetails.final_payment_date)).getTime())) {
      let earlistContactDate = new Date(this.getFormattedDate(accountDetails.earliest_contact_date));
      return todaysDate.getTime() >= earlistContactDate.getTime();
    } else {
      return false;
    }
  }

  isContractWithinDays(days: number): boolean {
    let accountDetails = this.datastoreService.getAccountDetails();
    if (accountDetails?.final_payment_date) {
      const daysLeft = calculateDaysLeft(accountDetails?.final_payment_date);
      if (daysLeft <= days && daysLeft > 0){
        return true;
      } 
    }
    return false;
  }

  isContractEndingWithinYear(completionDate: string) {
    if(completionDate) {
      let daysRemainingForEoc = this.getDifferenceBetweenDates(this.getFormattedDate(completionDate));
      return daysRemainingForEoc < 365;
    } else {
      return false;
    }
  }

  getLastDateForDecision(completionDate: string) {
    let noOfDays: number = 14;
    let formattedCompletionDate = new Date(this.getFormattedDate(completionDate));
    let lastDayForDecision = new Date(formattedCompletionDate);
    lastDayForDecision.setDate(formattedCompletionDate.getDate() - noOfDays)
     return new Date(lastDayForDecision)
  }
  
  /*
  * Function to return good condition guide url
  * Argument: brandName: brand name/ portal name, vehicle type, EOCwidgetContent for good conditionguide
  */
  getGoodConditionLink(brandName: string, vehicleType: string, content: any, goodsCode: any) {
    if(brandName === 'blackhorse' && vehicleType === 'ALM' && !appConfig.taxiGoodsCodes.includes(goodsCode)) {
      return content.blackHorseAllTypesMotorVehicle;
    } else if (brandName === 'blackhorse' && vehicleType === 'MCL') {
      return content.blackhorseBikeScooter;
    } else if (brandName === 'blackhorse' && vehicleType === 'CRV') {
      return content.blackhorseTouringCarvan;
    } else if (brandName === 'blackhorse' && vehicleType === 'ALM' && appConfig.taxiGoodsCodes.includes(goodsCode)) {
      return content.blackhorseTaxi;
    } else if (brandName === 'BlackHorseOffshore' && vehicleType === 'ALM') {
      return content.blackhorseOffshoreScooter;
    } else if (brandName === 'BlackHorseOffshore' && vehicleType === 'MCL') {
      return content.blackhorseOffshoreBikeScooter;
    } else if (brandName === 'jaguar') {
      return content.jaguar;
    } else if (brandName === 'landrover') {
      return content.landRover;
    } else if (brandName === 'mitsubishishogun') {
      return content.mitsubishi;
    } else if (brandName === 'halifax') {
      return content.halifax;
    } else if (brandName === 'lloyds') {
      return content.lloyds;
    }  else if (brandName === 'bankofscotland') {
      return content.bankOfScotland;
    } else if (brandName === 'suzukifinance' && vehicleType === 'ALM') {
      return content.suzukiCar;
    } else if (brandName === 'suzukifinance' && vehicleType === 'MCL') {
      return content.suzukiBike;
    } else if (brandName === 'internationalmotors') {
      return content.imf;
    } else if (brandName === 'astonmartin') {
      return content.astonmartin;
    }

  }

  /*
  * Function to return Dealers details
  * Argument: dealerNumber: Dealer_number
  */
  getDealersDetails(dealerNumber: any) {
    return this.httpService.getDealerDetails(dealerNumber)
    .pipe(
      map((res) => {
        this.datastoreService.setDealersDetails(res);
        return res;
      },
      catchError((error) => {
        this.messageService.setServiceError(error.error[0]);
        return error;
      }))
    )
  }

  /*
  * Function to save Keep your vehicle option
  */
  saveKeepYourVehicleOption() {
    this.requestService.setKeepVehicleOptionForEoc();
    return this.httpService.saveEocOption()
    .pipe(
      map(res => {
        return res
      }),
      catchError(error => {
        this.messageService.setEOCServiceError(error.error[0]);
          return error;
      })
    )
  }

  /*
  * Function to save Return vehicle option
  */
  saveReturnVehicleOption() {
    this.requestService.setReturnVehicleOptionForEoc();
    return this.httpService.saveEocOption()
    .pipe(
      map(res => {
        return res
      }),
      catchError(error => {
        this.messageService.setEOCServiceError(error.error[0]);
          return error;
      })
    )
  }

  /*
  * Function to save Part exchange option
  */
  savePartExchangeOption() {
    this.requestService.setPartExchangeOptionForEoc();
    return this.httpService.saveEocOption()
    .pipe(
      map(res => {
        return res
      }),
      catchError(error => {
        this.messageService.setEOCServiceError(error.error[0]);
          return error;
      })
    )
  }

  saveExtendVehicleOption() {
    this.requestService.setExtendVehicleOptionForEoc();
    return this.httpService.saveEocOption()
    .pipe(
      map(res => {
        return res
      }),
      catchError(error => {
        this.messageService.setEOCServiceError(error.error[0]);
          return error;
      })
    )
  }

  setRTF(flag: string) {
    return this.httpService.setRTF(flag)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          this.messageService.setEOCServiceError(error.error && error.error.length ? error.error[0] : error.error);
          return throwError(error);
        })
      )
  }
  
  setIsBalloonRefinanceBannerClosed(flag: boolean = true) {
    this.isBalloonRefinanceBannerClosed = flag;
  }

  getIsBalloonRefinanceBannerClosed() {
    return this.isBalloonRefinanceBannerClosed;
  }

  isPCPBalloonRefinanceCustomer() {
    let accountDetails = this.datastoreService.getAccountDetails();
    if((accountDetails?.scheme_code === appConfig.pcpCustomer || accountDetails?.scheme_code === appConfig.pcpCustomer.toString())
      && (accountDetails?.completion_date === null || accountDetails?.completion_date === '') 
      && (accountDetails?.final_payment_amount === '0' || accountDetails?.final_payment_amount === '£0')
      && (accountDetails?.is_modifier_indicator)) {
        return true;
      } else {
        return false;
      }
  }

  refreshAccountsData() {
    this.accountsService.getAccountSummary()
    .subscribe((res: any) => {
      
    })
  }

  postCustomerComments(payload: any) {
    return this.httpService.postCustomerComments(payload)
    .pipe(
      map((res: any) => {
        return res;
      },
      catchError((error: any) => {
        this.messageService.setServiceError(error.error && error.error.length ? error.error[0] : error.error);
        return error;
      }))
    )
  }
}
