import { Component, OnInit, ChangeDetectorRef, HostListener, OnDestroy, Inject } from '@angular/core';
import { WSSAppConstant, ContentData, appConfig, proposalStatus } from '@wss/config/wss-app-constants';
import { AccoutSummaryService } from './services/account-summary.service';
import { AccountSummary, AdditionalInfo, Arrear, CarbonIoffsetBanner, Contract, MyAgreement, SpeedometerData } from './model/account-summary';
import { NextPayment } from '@wss/model/nextPayment';
import { DatastoreService } from '@wss/service/datastore.service';
import { RouterService } from "@backbase/foundation-ang/core";
import { MessageService } from 'libs/service/message.service';
import { BannerMessage } from 'libs/model/bannerMessage';
import {
  isErrServer,
  getDate,
  isEmpOrNullOrUndef,
  isNotEmpOrNullOrUndef,
  isPreviouslyStartedBrJourney,
  setResumeBrApplciationLink,
  isPcpToHpBalloonRefinance,
  getIconColor,
  isLPAgreement
} from 'libs/common/util/util';
import { Observable, Subject } from 'rxjs';
import { DashboardWidgetItem } from '@wss/model/content/dashboard';
import { ContentService } from '@backbase/universal-ang/content';
import { finalize, takeUntil } from 'rxjs/operators';
import { EndOfContractService } from '@wss/end-of-contract-widget/src/services/end-of-contract.service';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import { TealiumUtagService } from '@wss/service/utag.service';
import { ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { BalloonRefinanceService } from '@wss/balloon-refinance-widget/src/services/balloon-refinance.service';
import { LoginService } from '@wss/login-widget/src/service/login.service';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '@wss/service/storage.service';
import { DataService } from '@wss/complaints-widget/src/services/data.service';

declare let document: any;

@Component({
  selector: 'bb-account-summary-widget',
  templateUrl: 'account-summary-widget.component.html',
  providers: [ContentService]
})

export class AccountSummaryWidgetComponent implements OnInit, OnDestroy {

  contentTxt: string = ContentData.DashboardSummary;
  contentFolder: string = ContentData.Dashboard;
  content: Array<any> = [];
  innerWidth: any;
  customerDetail: any = {};
  svgInfoIcon = WSSAppConstant.SVG_INFO_ICON;
  svgIconColor = getIconColor();
  isArrearMsgHidden: boolean = true;
  isFirstLogin: boolean = false;
  paperlessBannerFlag: boolean = false;
  accountSummary: AccountSummary = {
    arrearData: new Arrear(),
    myAgreementData: new MyAgreement(),
    speedometerData: new SpeedometerData(),
    contractData: new Contract(),
    nextPaymentData: new NextPayment(),
    additionalInfoData: new AdditionalInfo(),
    carbonIoffsetBanner: new CarbonIoffsetBanner()
  };

  bannerMessage: BannerMessage = new BannerMessage();
  serviceError: boolean = false;
  isSpeedometerActive: boolean = false;
  largeScreen: boolean = false;
  modalRef: any;
  readonly destroy$ = new Subject();
  loginResponse: any = {};
  routeUrlInBanner: any;
  showEocBanner: boolean = false;
  showEocBallonRefinaryBanner: boolean = false;
  isShowEocBannerForSeletedOption: boolean = false;
  within90days: boolean = false;
  showCustomerIntention: boolean = false;
  accountDetail: any = null;
  isAccountCompleted: boolean = false
  portalName: string = this.pageConfig.portalName || 'blackhorse';
  isAgreementInArrears: boolean = false;
  brandsForCarbon: any;
  dashboardWidgetItem: any;
  showCarbonOffsetContent: boolean = false;
  isShowContactPreferencePopup: boolean = false;
  isShowContactPreferenceBanner: boolean = false;
  isAccountArchived: boolean = false;
  contactPreferencePopup: number = 1;
  contactPreferenceBanner: number = 2;
  isShowEocBannerContactPreference: boolean = false;
  isEocBrReturnJourneyBannerClosed: boolean = false;
  proposalData: ProposalRequest | undefined;
  isResumableBrApplication: boolean = false;
  resumeBrApplicationLink: string = '';
  isValidForBalloonRefinanceFlag: boolean = false;
  decisionData: any;
  isPcpToHpBalloonRefinanceFlag: any;
  isProposalExists: boolean = false;
  proposalStatus: any;
  isDecisionChanged: any;
  reviewYourApplicationResultText: any;
  eocOptionBannerLinkId: string = '';
  applicationRejectedByCustomer: any;
  isLPAgreementFlag: any;
  hasExtendedOffline: boolean = false;
  redirectToSettlement: boolean = false;
  customerId: any = '';
  accountSummaryMethod: boolean = false;
  customerDetailResponse: boolean = false;
  redirectionPageList: any[] = ['settlement', 'makePaymentFAQs', 'getInTouch', 'voluntary-termination', 'complaints', 
    'complaintsJourney', 'Vehicle', 'Others'];
  userDetailResponse: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.getScreenSize();
  }

  dashboardWidgetItem$: Observable<DashboardWidgetItem | undefined> = this.bbContentService.getContent<DashboardWidgetItem>('dashboardWidgetItem');

  constructor(
    private accountSummaryService: AccoutSummaryService,
    private datastoreService: DatastoreService,
    public changeDetectorRef: ChangeDetectorRef,
    private router: RouterService,
    private messageService: MessageService,
    private readonly bbContentService: ContentService,
    private eocService: EndOfContractService,
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
    private tealiumService: TealiumUtagService,
    private balloonRefinanceSrv: BalloonRefinanceService,
    private loginService: LoginService,
    private routerAct: ActivatedRoute,
    private formDataService: DataService,
    private storageService: StorageService
  ) {
    this.accountDetail = this.datastoreService.getAccountDetails();
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.datastoreService.sessionInvalid();
    this.tealiumService.view(
      {
        JourneyName: 'Account Summary',
        JourneyStepName: 'Account',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.customerId = this.storageService.getLocalStorage('customerId');
    this.messageService.setActiveNav("My account");
    this.isAccountCompleted = this.datastoreService.getIsAccountCompleted();
    this.accountDetail = this.datastoreService.getAccountDetails();
    this.customerDetail = this.datastoreService.getCustomerDetail();
    this.isAccountArchived = this.datastoreService.getIsAccountArchived();
    this.datastoreService.customerDetailResponse.subscribe(data => {
      this.customerDetailResponse = data;
    });
    this.datastoreService.loginResponse.subscribe(loginResponse => {
      this.loginResponse = loginResponse;
      if (this.loginResponse && this.customerDetailResponse && !this.userDetailResponse) {
        this.userDetailResponse = !this.userDetailResponse;
        this.loginResponse = this.datastoreService.getCustomerLogin();
        this.getApplicationConfig(this.portalName);
        this.datastoreService.setContactPreferenceData(this.getContactPreference(this.loginResponse));
        this.datastoreService.setContactPreferenceBannerShown(true);
        this.datastoreService.setContactPreferencePopupShown(true);
        this.updateEmailForFirstLogin(this.loginResponse);
      }
    });
    this.isAgreementInArrears = !(!this.accountDetail?.in_arrears || (this.accountDetail?.in_arrears && this.accountDetail?.months_in_arrears == 0));
    this.isEocBrReturnJourneyBannerClosed = this.datastoreService.getisEocBrResumeAppBannerClosed();
    this.getAccountSummary();
    this.getMailboxMsg();
    if (!this.isAccountCompleted) {
      this.getMailPreference();
    }
    this.getCarbonOffsetDetails();
    this.handleErrors();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getScreenSize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1023) {
      this.largeScreen = true;
    } else {
      this.largeScreen = false;
    }
  }

  updateEmailForFirstLogin(loginResponse: any) {
    let todayDate = new Date().toISOString();
    let { date_Email_Updated, alias, is_Email_Updated } = loginResponse;
    if (date_Email_Updated == "") {
      this.loginService.UpdateContact({ email_address: alias }).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this.updateContactPreferenceOrEmailData(todayDate, true, loginResponse);
      },
        (error: any) => {
          this.updateContactPreferenceOrEmailData(todayDate, false, loginResponse);
        });
    } else {
      this.updateContactPreferenceOrEmailData(date_Email_Updated, is_Email_Updated, loginResponse);
    }
  }

  updateContactPreferenceOrEmailData(emailUpdatedDate: any, isEmailUpdated: boolean, loginResponse: any) {
    if (Number(loginResponse['marketing_Display']) <= 2) {
      loginResponse['marketing_Display'] = (Number(loginResponse["marketing_Display"]) + 1).toString();
    }
    loginResponse['date_Marketing'] = new Date().toISOString();
    loginResponse["date_Email_Updated"] = emailUpdatedDate;
    loginResponse["is_Email_Updated"] = isEmailUpdated;
    this.loginService.UpdateUserDetails(loginResponse).pipe(takeUntil(this.destroy$),
      finalize(() => {
        this.navigateAfterLogin(loginResponse);
      })
    ).subscribe();
  }

  getContactPreference(obj: any) {
    const { marketing_Display, marketing_Flag } = obj;
    return { marketingDisplayCount: Number(marketing_Display), marketingFlag: marketing_Flag };
  }

  navigateAfterLogin(loginResponse: any) {
    let concernType: any = {}
    this.routerAct.queryParams.subscribe(params => {
      let urlQueryParam = '';
      let redirectionParam = atob(decodeURIComponent(params['redirectTo']));
      let complaintJourneyParam = atob(decodeURIComponent(params['complaintsJourney']));
      if (params['redirectTo']) {
        urlQueryParam = this.redirectionPageList.indexOf(redirectionParam) !== -1 ? redirectionParam : params['redirectTo'];
      }
      if (params['complaintsJourney']) {
        complaintJourneyParam = this.redirectionPageList.indexOf(complaintJourneyParam) !== -1 ? complaintJourneyParam : params['complaintsJourney'];
      }
      if (urlQueryParam === 'settlement') {
        localStorage.setItem('redirectionFlag', 'settlement');
        this.redirectToSettlement = true;
        document.getElementById("appLoader").style.display = "block";
        document.querySelector("body").classList.add("block-ui");
        localStorage.setItem('settlementRedirectionFlag', 'true');
      } else if (urlQueryParam === 'makePaymentFAQs') {
        localStorage.setItem('makePaymentFAQsRedirection', 'true');
      }
      else if (urlQueryParam === 'getInTouch') {
        localStorage.setItem('getInTouchRedirection', 'true');
      }
      else if (urlQueryParam == 'voluntary-termination') {
        localStorage.setItem('voluntaryTerminationRedirection', 'true');
      }
      else if ((urlQueryParam === 'complaints' && complaintJourneyParam === 'Vehicle')
        || (params['redirectTo'] === 'complaints' && params['complaintsJourney'] === 'Vehicle')) {
        concernType = { concernType: 'Vehicle' };
        localStorage.setItem('complaintsRedirection', 'true');
        this.formDataService.saveFormData('concernSelectionForm', concernType);
      } else if ((urlQueryParam === 'complaints' && complaintJourneyParam === 'Others')
        || (params['redirectTo'] === 'complaints' && params['complaintsJourney'] === 'Others')) {
        concernType = { concernType: 'FinanceAgreement' };
        localStorage.setItem('complaintsRedirection', 'true');
        this.formDataService.saveFormData('concernSelectionForm', concernType);
      }
      else {
        this.redirectToSettlement = false;
        localStorage.setItem('settlementRedirectionFlag', 'false');
        localStorage.setItem('makePaymentFAQsRedirection', 'false');
        localStorage.setItem('getInTouchRedirection', 'false');
        localStorage.setItem('complaintsRedirection', 'false');
        localStorage.setItem('voluntaryTerminationRedirection', 'false');
      }
    });
    this.changeDetectorRef.markForCheck();
  }

  getApplicationConfig(brandName: any) {
    this.loginService.getDefaultConfiguration().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response?.propertySources[0]?.source[`mfvs.feature.marketing-preferences.enabled`]) {
        let isFeatureEnabled: boolean = response?.propertySources[0]?.source[`mfvs.feature.marketing-preferences.${brandName}.enabled`];
        this.datastoreService.setIsContactPreferenceFeature(isFeatureEnabled);
      } else {
        this.datastoreService.setIsContactPreferenceFeature(false);
      }
      if (response?.propertySources[0]?.source[`mfvs.feature.complaint-switch.enabled`]) {
        let isComplaintFeatureEnabled: boolean = response?.propertySources[0]?.source[`mfvs.feature.complaint-switch.${brandName}.enabled`];
        this.datastoreService.setComplaintsFeature(isComplaintFeatureEnabled);
      } else {
        this.datastoreService.setComplaintsFeature(false);
      }
      this.changeDetectorRef.detectChanges();
    }, (error: any) => {
      if (error) {
        this.datastoreService.setComplaintsFeature(false);
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  showError(errStatus: number): void {
    if (isErrServer(errStatus)) {
      this.datastoreService.setErrorServer();
      this.serviceError = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  getAccountSummary() {
    this.accountSummaryService.getAccountSummary().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.accountDetail = this.datastoreService.getAccountDetails();
      this.accountDetail.scheme_code = parseInt(this.accountDetail.scheme_code);
      this.accountSummary = this.datastoreService.getMyAccount();
      this.isPcpToHpBalloonRefinanceFlag = isPcpToHpBalloonRefinance(this.accountDetail);
      this.isLPAgreementFlag = isLPAgreement(this.accountDetail);
      this.isArrearMsgHidden = this.datastoreService.getShowArrear();
      this.isSpeedometerActive = true;
      let settlementRedirectionFlag = localStorage.getItem('settlementRedirectionFlag');
      let makePaymentFAQsRedirection = localStorage.getItem('makePaymentFAQsRedirection');
      let getInTouchRedirection = localStorage.getItem('getInTouchRedirection');
      let complaintsRedirection = localStorage.getItem('complaintsRedirection');
      let vtRedirection = localStorage.getItem('voluntaryTerminationRedirection');
      this.datastoreService.setVehicleType();
      this.datastoreService.setCheckVehicleType();
      if (settlementRedirectionFlag === "true") {
        location.href = appConfig.settlementURL;
      } else if (makePaymentFAQsRedirection === "true") {
        location.href = appConfig.makePaymentFAQsURL;
      } else if (getInTouchRedirection === "true") {
        location.href = appConfig.getInTouchURL;
      } else if (complaintsRedirection === "true") {
        location.href = appConfig.complaintsLandingURL;
      } else if (vtRedirection === "true") {
        location.href = appConfig.voluntaryTerminationURL;
      }
      this.within90days = this.eocService.isContractWithinNintyDays();
      this.isValidForBalloonRefinanceFlag = this.isValidForBalloonRefinance();
      this.proposalData = this.datastoreService.getProposalRequest();
      this.isProposalExists = this.accountDetail?.is_proposal_exists;
      if (this.within90days && this.isValidForBalloonRefinanceFlag && this.isProposalExists) {
        this.getProposalData();
      } else {
        this.showEocBannerMessage();
        this.showBalloonRefinaryBanner();
        this.showEocBannerForSeletedOption();
        setTimeout(() => {
          this.contactPreferencePopupOrBanner();
        }, 100);
        this.changeDetectorRef.markForCheck();
      }
    },
      (error: any) => { });
  }

  toggleArrearMsgBox(value: boolean) {
    this.isArrearMsgHidden = value;
  }

  navigate(url: string) {
    this.router.navigate([url]);
  }

  handleErrors() {
    this.messageService.getServiceError().pipe(takeUntil(this.destroy$)).subscribe((error: any) => {
      this.showError(error?.status);
    });
  }

  getMailPreference() {
    this.accountSummaryService.getMailPreference().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      let today = new Date();
      let interstitialInSession = this.datastoreService.getInterstitialPopupShown();
      let paperlessInSession = this.datastoreService.getPaperlessBannerShown();
      let preferenceUpdatedFlag = this.datastoreService.getIsPreferenceUpdated();
      let digiIndoxStartDate = res && res.digital_inbox_start_date && res.digital_inbox_start_date.length > 0 ? getDate(res.digital_inbox_start_date.split(" ")[0]) : "";
      if (res && !res.is_paperless && (today >= digiIndoxStartDate)) {
        if (isEmpOrNullOrUndef(this.loginResponse.last_login) && !interstitialInSession) {
          this.datastoreService.setInterstitialPopupShown();
          this.datastoreService.setPaperlessBannerShown();
          this.isFirstLogin = true;
        }
        else if (isNotEmpOrNullOrUndef(this.loginResponse.last_login) && (getDate(this.loginResponse.last_login.split(" ")[0]) <= digiIndoxStartDate) && !interstitialInSession) {
          this.datastoreService.setInterstitialPopupShown();
          this.datastoreService.setPaperlessBannerShown();
          this.isFirstLogin = true;
        }
        else if (!paperlessInSession && !preferenceUpdatedFlag) {
          this.datastoreService.setPaperlessBannerShown();
          this.paperlessBannerFlag = true;
        }
      }
      this.changeDetectorRef.markForCheck();
    },
      (error: any) => {
        this.showError(error?.status);
      });
  }

  getMailboxMsg() {
    this.accountSummaryService.getMailboxMsg().pipe(takeUntil(this.destroy$)).subscribe(() => {
    },
      (error: any) => {
        this.showError(error?.status);
      });
  }

  showEocBannerMessage() {
    let isPCPBalloonRefinanceCustomer = this.eocService.isPCPBalloonRefinanceCustomer();

    if (this.isArrearMsgHidden && this.eocService.isContractWithinDays(89) && !appConfig.fileTags.includes(this.accountDetail?.file_tag)
      && appConfig.goodsCode.includes(this.accountDetail.goods_code)
      && (this.accountDetail?.scheme_code === '54' || this.accountDetail?.scheme_code === appConfig.pcpCustomer)
      && !this.isAgreementInArrears && !isPCPBalloonRefinanceCustomer && !this.isEocBrReturnJourneyBannerClosed) {
      this.isShowEocBannerContactPreference = true;
      this.bannerMessage.closeIconClass = "clear";
      this.bannerMessage.infoTypeClass = "icon_warining_amber";
      this.bannerMessage.leftSectionColorClass = "message-warning";
      this.bannerMessage.materialIconClass = "warning_amber";
      this.dashboardWidgetItem$.subscribe((res: any) => {
        this.dashboardWidgetItem = res;
        this.bannerMessage.titleText = res.bannerMessage.eocBannerDueText + this.accountDetail.final_payment_date + '.';
        this.bannerMessage.isBannerClosed = false;
        this.messageService.setBannerMessage(this.bannerMessage);
        this.showEocBanner = true;
        this.reviewYourApplicationResultText = res?.bannerMessage.reviewYourApplicationResultText;
      })
    } else {
      this.showEocBanner = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  showBalloonRefinaryBanner() {
    let isBalloonrefinanceBannerClosed = this.eocService.getIsBalloonRefinanceBannerClosed();
    let isPCPBalloonRefinanceCustomer = this.eocService.isPCPBalloonRefinanceCustomer();

    if (this.isArrearMsgHidden && !appConfig.fileTags.includes(this.accountDetail?.file_tag) && !this.isAgreementInArrears && this.eocService.isContractWithinDays(90)
      && (this.accountDetail?.scheme_code === '01' || this.accountDetail?.scheme_code === appConfig.hpCustomer || this.isLPAgreementFlag || isPCPBalloonRefinanceCustomer)
      && !isBalloonrefinanceBannerClosed) {
      this.isShowEocBannerContactPreference = true;
      this.bannerMessage.closeIconClass = "clear";
      this.bannerMessage.infoTypeClass = "icon_info_blue";
      this.bannerMessage.leftSectionColorClass = "message-info";
      this.bannerMessage.materialIconClass = "info";
      this.dashboardWidgetItem$.subscribe((res: any) => {
        this.bannerMessage.titleText = res.bannerMessage.eocBannerDueText + this.accountDetail.final_payment_date + '.';
        this.bannerMessage.isBannerClosed = false;
        this.messageService.setBannerMessage(this.bannerMessage);
        this.showEocBallonRefinaryBanner = true;
      })
    } else {
      this.showEocBallonRefinaryBanner = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  showEocBannerForSeletedOption() {
    if (this.isArrearMsgHidden && (this.accountDetail?.customer_intention == 'F' || this.accountDetail?.customer_intention == 'R' ||
      this.accountDetail?.customer_intention == 'T' || this.accountDetail?.customer_intention == 'P' || this.accountDetail?.customer_intention === 'E')
      && (this.accountDetail?.scheme_code === '54' || this.accountDetail?.scheme_code === appConfig.pcpCustomer)
      && !this.isAgreementInArrears && !appConfig.fileTags.includes(this.accountDetail?.file_tag) && this.within90days) {
      this.isShowEocBannerContactPreference = true;
      this.bannerMessage.closeIconClass = "clear";
      this.bannerMessage.infoTypeClass = "icon_info_blue";
      this.bannerMessage.leftSectionColorClass = "message-info";
      this.bannerMessage.materialIconClass = "info";
      this.dashboardWidgetItem$.subscribe((res: any) => {
        this.bannerMessage.titleText = res.bannerMessage.eocBannerDueText + this.accountDetail.final_payment_date + '.';
        switch (this.accountDetail.customer_intention) {
          case 'E':
            if (!this.proposalData || (this.proposalData && !this.proposalData?.proposal_status)) {
              this.hasExtendedOffline = true;
              this.bannerMessage.subtitleText = res.bannerMessage.hasExtendedOfflineText;
            } else {
              this.bannerMessage.subtitleText = res.bannerMessage.eocSelectedOptionText + res.bannerMessage.balloonRefinanceVehicleText;
              this.datastoreService.setEocRoute("balloon-refinance-getdetails/mod-ag-next-step");
              this.routeUrlInBanner = '/balloon-refinance-getdetails/mod-ag-next-step';
              this.eocOptionBannerLinkId = 'wss-banner-eoc-intention-balloon-refinance';
            }
            break
          case 'F':
            this.bannerMessage.subtitleText = res.bannerMessage.eocSelectedOptionText + res.bannerMessage.keepVehicleText;
            this.datastoreService.setEocRoute("confirmation");
            this.routeUrlInBanner = '/eoc-options/confirmation';
            this.eocOptionBannerLinkId = 'wss-banner-eoc-intention-keep-vehicle';
            break
          case 'R':
            this.bannerMessage.subtitleText = res.bannerMessage.eocSelectedOptionText + res.bannerMessage.returnVehicleText;
            this.datastoreService.setEocRoute("return-vehicle-confirmation");
            this.routeUrlInBanner = '/eoc-options/return-vehicle-confirmation'
            this.eocOptionBannerLinkId = 'wss-banner-eoc-intention-return-vehicle';
            break
          case 'T':
            this.bannerMessage.subtitleText = res.bannerMessage.eocSelectedOptionText + res.bannerMessage.partExchangeText;
            this.datastoreService.setEocRoute("part-exchange-confirmation");
            this.routeUrlInBanner = '/eoc-options/part-exchange-confirmation'
            this.eocOptionBannerLinkId = 'wss-banner-eoc-intention-part-exchange';
            break
          case 'P':
            this.bannerMessage.subtitleText = res.bannerMessage.eocSelectedOptionText + res.bannerMessage.partExchangeText;
            this.routeUrlInBanner = '/eoc-options/part-exchange-confirmation'
            this.eocOptionBannerLinkId = 'wss-banner-eoc-intention-part-exchange';
            break
          default:
            break
        }
        this.bannerMessage.titleText = res.bannerMessage.eocBannerDueText + this.accountDetail.final_payment_date + '.';
        this.bannerMessage.isBannerClosed = false;
        this.messageService.setBannerMessage(this.bannerMessage);
        this.isShowEocBannerForSeletedOption = true;
        this.showCustomerIntention = true;
        this.changeDetectorRef.detectChanges();
      })
    } else {
      this.isShowEocBannerForSeletedOption = false;
    }
  }

  contactPreferencePopupOrBanner() {
    let isBannerNotClosed = this.datastoreService.getContactPreferenceBannerShown();
    let isPopupNotClosed = this.datastoreService.getContactPreferencePopupShown();
    let { marketingDisplayCount, marketingFlag } = this.datastoreService.getContactPreferenceData();
    let isContactPreferenceFeatureOn = this.datastoreService.getIsContactPreferenceFeature();
    if (!this.isAccountArchived && marketingDisplayCount == this.contactPreferencePopup && !marketingFlag && isPopupNotClosed && isContactPreferenceFeatureOn) {
      this.isShowContactPreferencePopup = true;
    } else if (!this.isShowEocBannerContactPreference && !this.isAccountArchived && marketingDisplayCount >= this.contactPreferenceBanner && !marketingFlag && isBannerNotClosed && isContactPreferenceFeatureOn) {
      this.routeUrlInBanner = "/contact-preference/landing";
      this.bannerMessage.closeIconClass = "clear";
      this.bannerMessage.infoTypeClass = "icon_info_blue";
      this.bannerMessage.leftSectionColorClass = "message-info";
      this.bannerMessage.materialIconClass = "info_outline";
      this.dashboardWidgetItem$.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.bannerMessage.titleText = res.contactPreferenceBanner.titleText;
        this.bannerMessage.subtitleText = res.contactPreferenceBanner.bodyText;
        this.bannerMessage.isBannerClosed = false;
        this.messageService.setBannerMessage(this.bannerMessage);
        this.isShowContactPreferenceBanner = true;
        this.changeDetectorRef.detectChanges();
      })
    } else {
      this.isShowContactPreferencePopup = false;
      this.isShowContactPreferenceBanner = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  navigateToManageAgreement() {
    this.datastoreService.setEocRoute("manage-contract");
    this.messageService.setActiveNav("End of contract");
    this.router.navigate(['/eoc-options/manage-contract']);
    window.scroll(0, 0);
  }

  getCarbonOffsetDetails() {
    this.dashboardWidgetItem$.subscribe((res: any) => {
      this.dashboardWidgetItem = res;
      this.brandsForCarbon = this.dashboardWidgetItem.carbonIoffset?.brandsToShowCarbonOffsetContent?.split(', ');
      if (this.brandsForCarbon?.indexOf(this.portalName) === -1) {
        this.showCarbonOffsetContent = false;
      } else {
        this.showCarbonOffsetContent = true;
      }
    });
  }

  getProposalData() {
    this.balloonRefinanceSrv.getProposalDetails().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.proposalData = this.datastoreService.getProposalRequest();
      if (this.proposalData) {
        this.proposalStatus = this.proposalData?.proposal_status;
        this.isDecisionChanged = this.proposalData?.IsDecision_Changed === null ? false : this.proposalData?.IsDecision_Changed;
        if (this.proposalData?.IsRejectedByCustomer !== true && !(this.proposalStatus === proposalStatus.declined && this.isDecisionChanged === false)) {
          this.isResumableBrApplication = isPreviouslyStartedBrJourney(this.proposalData);
          this.resumeBrApplicationLink = setResumeBrApplciationLink(this.proposalData, this.accountDetail, this.eocService.isContractWithinDays(14));
        }
      }
      this.changeDetectorRef.detectChanges();
    },
      (error: any) => {
        if (error.status === 404) {
          if (error.error.error !== 'Not Found') {
            if (error.error[0].code !== 24001) {
              this.showError(error?.status);
            }
          }
        } else {
          this.showError(error?.status);
        }
      }).add(() => {
        this.showEocBannerMessage();
        this.showBalloonRefinaryBanner();
        this.showEocBannerForSeletedOption();
        setTimeout(() => {
          this.contactPreferencePopupOrBanner();
        }, 100);
        this.changeDetectorRef.markForCheck();
      });
  }

  isValidForBalloonRefinance(): boolean {
    return this.accountDetail?.pcp_ind && this.accountDetail?.regulated_ind && this.accountDetail?.palis_scheme !== '5A' && appConfig.brandsEligibleForBalloonRefinance.includes(this.portalName);
  }
}

