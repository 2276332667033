import { months } from "moment";

export class RuntimeEnv {
  public static backbase: boolean = true;
}

export class appConfig {
  public static companyId: string = '02';
  public static pageTile: string = 'Black Horse | Welcome to your online account';
  public static errorEmailRegistration: string = 'There is already an account registered with that email address.';
  public static errorPleaseText: string = 'Please ';
  public static errorEmailRegistrationLink: string = 'try again with a different email address';
  public static loginTry: number = 3;
  public static hpCustomer: number = 1;
  public static pcpCustomer: number = 54;
  public static appTimeout: number = 900;
  public static loginURL: string = 'login';
  public static regURL: string = 'registration';
  public static welcomeURL: string = 'welcome';
  public static mtaPreprodLoginURL: string = '/';
  public static mtaProdLoginURL: string = '/public/login';
  public static mtaIntLoginURL: string = 'https://int.customerportal.blackhorse.co.uk/';
  public static workingDaysCalculation: number = 5; //NOSONAR
  //for running in backbase
  public static dashboardURL: string = 'dashboard';
  public static mailboxURL: string = 'dashboard#/mailbox/mailbox';
  public static settlementURL: string = 'dashboard#/payments/payment-settlement';
  public static makePaymentFAQsURL: string = 'dashboard#/help-centre/make-payment';
  public static getInTouchURL: string = 'dashboard#/help-centre/get-in-touch';
  public static complaintsURL: string = 'dashboard#/complaint/get-started';
  public static complaintsLandingURL: string = 'dashboard#/complaint/relate-to';
  public static voluntaryTerminationURL: string = 'dashboard#/eoc-options/voluntary-termination';
  public static yearStart: number = new Date().getFullYear() - 99;
  public static hCountMax: number = 4;
  public static sCode: number = 123654789321;
  public static vehicleGeneralAge: number = 168;
  public static vehicleElectricAge: number = 96;
  public static bikeMinAdvance: number = 400;
  public static carMinAdvance: number = 2000;
  public static maxAdvance: number = 60260;
  public static defaultIconColor: string = '#107EB1';

  //for running standalone locally
  // public static dashboardURL: string = '#/myaccount';
  public static NoOfTransactions: number = 20;
  public static jagaurBudgetToolLink: string = "https://apply.lloydsbank.co.uk/sales-content/cwa/l/gforms/F234/#//?b=jl";
  public static otherBrandBudgetToolLink: string = "https://apply.lloydsbank.co.uk/sales-content/cwa/l/gforms/F234/#//?b=bh"
  public static carbonOffsetLink: string = "https://ioffset.io/calculate-driving-emissions";
  public static brandDetails = [
    { brandName: 'blackhorse', companyNumber: '02', pageTile: 'Black Horse | Welcome to your online account', agreementDisplayName: 'Black Horse Ltd', creditorsName: 'Black Horse Ltd', customerService: '0344 824 8888', complainNo: '0800 302 9157', iconColor: '#00665E'},
    { brandName: 'halifax', companyNumber: '85', pageTile: 'Halifax UK | Welcome to Car Plan Extra', iconColor: '#107EB1'},
    { brandName: 'lloyds', companyNumber: '87', pageTile: 'Lloyds Bank | Welcome to your Car Finance account', iconColor: '#107EB1'},
    { brandName: 'jaguar', companyNumber: '11', pageTile: 'Jaguar Financial Services | Welcome to Jaguar My Finance', agreementDisplayName: 'Jaguar Financial Services', creditorsName: 'Black Horse Ltd t/a Jaguar Financial Services', customerService: '0344 824 4712', complainNo: '0800 151 2461', iconColor: '#107EB1'},
    { brandName: 'landrover', companyNumber: '13', pageTile: 'Land Rover Financial Services | Welcome to Land Rover My Finance', agreementDisplayName: 'Land Rover Financial Services', creditorsName: 'Black Horse Ltd t/a Land Rover Financial Services', customerService: '0344 824 4732', complainNo: '0800 3029151', iconColor: '#107EB1'},
    { brandName: 'suzukifinance', companyNumber: '31', pageTile: 'Suzuki Finance | Welcome to your online account', agreementDisplayName: 'Suzuki Financial Services', creditorsName: 'Suzuki Financial Services', customerService: '0344 824 0876', complainNo: '0800 302 9157', iconColor: '#107EB1' },
    { brandName: 'internationalmotors', companyNumber: '03', pageTile: 'International Motors Finance | Welcome to your online account', agreementDisplayName: 'International Motors Finance Limited', creditorsName: 'International Motors Finance Limited', customerService: '0344 556 0358', complainNo: '0800 302 9157', iconColor: '#107EB1' },
    { brandName: 'mitsubishishogun', companyNumber: '45', pageTile: 'Shogun Financial Services | Welcome to your online account', agreementDisplayName: 'Shogun Finance Ltd', creditorsName: 'Shogun Finance Ltd', customerService: '0344 824 8828', complainNo: '0800 302 9157', iconColor: '#107EB1' },
    { brandName: 'bankofscotland', companyNumber: '86', pageTile: 'Bank of Scotland | Welcome to your Car Finance Account', iconColor: '#107EB1' },
    { brandName: 'astonmartin', companyNumber: '89', pageTile: 'Aston Martin | Financial Services', iconColor: '#00665E' }
  ];
  public static envPhrase = { dev: 'dev01', sit: 'devtest', preprod: 'wss-ocp', preprodtest: '//test.' };
  public static envName = { dev: 'dev', sit: 'dev', preprod: 'qa', prod: 'prod' };
  public static tealiumAccount: string = 'lloyds';
  public static tealiumProfile: string = 'blackhorse';

  public static goodsCode = ['001', '002', '003', '004', '005', '006', '009', '010', '015', '016'];
  public static taxiGoodsCodes = ['086','087'];
  public static goodsCodeForVehicle = ['001', '002', '003', '004', '005', '006', '007', '008', '009', '010', '015', '016', '064', '065', '074', '075', '086', '087', '095', '098'];
  public static motorbikeGdsCodes = ['009','010','015','016','095','098'];
  public static carCodesForEoc = ['001', '002', '003', '004']
  public static lcvCodesForEoc = ['005', '006']
  public static goodsCodesForBR = ['001', '002', '003', '004','009','010','015','016','095','098']
  public static fileTags = ['05', '07', '13', '16', '17', '19', '21', '27', '33', '77'];
  public static vtRelatedTags = ['16','22','33','58'];
  public static eocPCPBanner: string = 'eocPCPBanner';
  public static eocHPBanner: string = 'eocHPBanner';
  public static eocIntentionSelectedBanner: string = 'eocIntentionSelectedBanner';
  public static brandObjectOnOff = [
    'blackhorse',
    'halifax',
    'bankofscotland',
    'lloyds',
    'jaguar',
    'landrover',
    'suzukifinance',
    'internationalmotors',
    'mitsubishishogun',
    'astonmartin'
  ];
  public static brandCompanyDetails = [
    { brandName: 'blackhorse', companyNumber: ['01', '02', '04', '06', '07', '14', '15', '21', '24', '25', '30', '37', '39', '59', '74'] },
    { brandName: 'halifax', companyNumber: ['85'] },
    { brandName: 'lloyds', companyNumber: ['87'] },
    { brandName: 'jaguar', companyNumber: ['11'] },
    { brandName: 'landrover', companyNumber: ['13'] },
    { brandName: 'suzukifinance', companyNumber: ['31'] },
    { brandName: 'internationalmotors', companyNumber: ['03'] },
    { brandName: 'mitsubishishogun', companyNumber: ['45'] },
    { brandName: 'bankofscotland', companyNumber: ['86'] },
    { brandName: 'astonmartin', companyNumber: ['89'] }
  ]

  public static vehicleUses = [
    { value: '0', title: 'Social' },
    { value: '1', title: 'Business' },
    { value: '2', title: 'Driving school' },
    { value: '3', title: 'Taxi hire' }
  ]

  public static monthsData = [
    { value: '00', title: 'January'},
    { value: '01', title: 'February'},
    { value: '02', title: 'March'},
    { value: '03', title: 'April'},
    { value: '04', title: 'May'},
    { value: '05', title: 'June'},
    { value: '06', title: 'July'},
    { value: '07', title: 'August'},
    { value: '08', title: 'September'},
    { value: '09', title: 'October'},
    { value: '10', title: 'November'},
    { value: '11', title: 'December'}
  ]

  public static residentStatus = [
    { value: '0', title: 'Owner' },
    { value: '1', title: 'Tenant' },
    { value: '2', title: 'Parents' },
    { value: '3', title: 'Other' }
  ]

  public static maritalStatus = [
    { value: '0', title: 'Married' },
    { value: '1', title: 'Cohabit' },
    { value: '2', title: 'Single' },
    { value: '3', title: 'Separated' },
    { value: '4', title: 'Divorced' },
    { value: '5', title: 'Widowed' }
  ]
  public static brandsEligibleForBalloonRefinance = [
    'blackhorse',
    'jaguar',
    'landrover',
    'suzukifinance',
    'internationalmotors',
    'mitsubishishogun',
    'astonmartin'
  ];

  public static unemployedTypes = [4,5,6,7,8];
}

export class brandName {
  public static BH: string = 'blackhorse';
  public static LD: string = 'lloyds';
  public static BS: string = 'bankofscotland';
  public static HF: string = 'halifax';  
  public static JR: string = 'jaguar';
  public static LR: string = 'landrover';
  public static SF: string = 'suzukifinance';
  public static IM: string = 'internationalmotors';
  public static MS: string = 'mitsubishishogun';
  public static AM: string = 'astonmartin';
}

export class appErrorConfig {
  public static warningIcon: string = 'icon_warning_amber vertical-align-center';
  public static warningStyle: string = 'message-warning';
}

export class WSSAppConstant {
  public static SVG_INFO_ICON: string =
    'M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z';
  public static SVG_WARNING_ICON: string = 'M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z';
}

export class ContentData {

  public static LoginPasswordSet: string = 'login-password-set';

  public static SettlementLanding: string = 'settlement-landing';
  public static MakePaymentLanding: string = 'make-payment-landing';
  public static SaveCardModal: string = 'make-payment-save-modal';
  public static ProcessModal: string = 'make-payment-process';
  public static PaymentResult: string = 'make-payment-result';
  public static smsModal: string = 'make-payment-sms';
  public static DirectDebitLanding: string = 'direct-debit';
  public static AddOrUpdateDirectDebit: string = 'add-direct-debit';
  public static DirectDebitException: string = 'direct-debit-expection';
  public static DirectDebitResult: string = 'direct-debit-result';
  public static PaymentMenu: string = 'payment-menu';
  public static ChangePayment: string = 'change-payment';
  public static ConfirmChangePayment: string = 'confirm-change-payment';
  public static ChangePaymentResult: string = 'change-payment-result';
  public static ServiceException: string = 'service-exception';
  public static Cohort1: string = 'cohort-1';
  public static Cohort3: string = 'cohort-3';
  public static PaymentHolidayAccordion: string = 'payment-holiday-accordion';
  public static PaymentHolidayLanding: string = 'payment-holiday-landing';
  public static TriageQuestion: string = 'triage-question';
  public static YourOptions: string = 'your-options';
  public static SendSMS: string = 'send-sms';
  public static NextPayment: string = 'next-payment';

  public static DashboardSummary: string = 'dashboard-summary';
  public static DashboardMultiple: string = 'dashboard-multiple-account';
  public static Dashboard: string = 'dashboard';
  public static Registration: string = 'registration';
  public static Login: string = 'login';

  public static Settlement: string = 'settlement';
  public static MakePayment: string = 'payment';
  public static DirectDebit: string = 'direct-debit';
  public static PaymentHoliday: string = 'payment-holiday';
  public static HelpCentre: string = 'help-centre';

  public static UpdateDetails: string = 'update-details';
  public static OverPayment: string = 'over-payment-landing';
  public static HelpCentreLanding: string = 'help-centre-landing';
  public static HelpCentreVehicleComplaints: string = 'help-centre-vehicle-complaints';
}

export class apiURI {
  public static contentURI = "api/contentservices/api/contentstream/contentRepository/wss/";
  public static cmnContentURI = "../api/contentservices/api/contentstream/contentRepository/wss/content/";
  public static localContentURI = "assets/content/";
  public static localDataURI = "assets/mockData/";
  public static authURI = "authorization";
  public static customerURI = "customers";
  public static customerOTPURI = "users?registration_option=OTP";
  public static forgotEmailOTPURI = "forgotUsername?forgotUsername_option=OTP";
  public static reqSentURI = "/api/wss-bb-service-registration/users/";
  public static customerId = "customers/CID000073873";
  public static transactionBaseURI = "statements-api/accounts/";
  public static transactionsURI = "transactions";
  public static statementsURI = "statements/";
  public static directDebitBaseURI = "bank-accounts-api/accounts/";
  public static bankAccountsURI = "bank-accounts";
  public static directDebitsURI = "direct-debits";
  public static usersURI = "users/";
  public static confirmURI = "/confirmation";
  public static accountsURI = "accounts";
  public static requestStatementURI = "accounts/530190649/statements";
  public static regURI = "api/wss-bb-service-registration/v2/";
  public static regisURIDecrypt = "api/wss-bb-service-registration/v3/";
  public static accURI = "api/wss-bb-service-api-proxy/wss/";
  public static baseURI = "/";
  public static loginURI = "api/wss-bb-service-login/login";
  public static loginURIDecrypt = "api/wss-bb-service-login/decrypt";
  public static userURI = "users";
  public static resetPwdURI = "password-reset";
  public static resetPwdURIGetLink = "reset";
  public static verifyPwdURI = "verify";
  public static checkPwdURI = "check-password";
  public static forgotUsernameURI = "forgotUsername/";
  public static accountMngURI = "api/wss-bb-service-account-management/";
  public static settlementsURI = "settlements";
  public static settlementsApiURI = "settlements-api/";
  public static cardURI = "cards";
  public static cardPaymentURI = "card-payments";
  public static customersURI = "customers";
  public static addressesURI = "addresses";
  public static emailURI = "emails/send-email";
  public static smsURI = 'sms/notification';
  public static changePaymentBaseURI = "due-dates-api/accounts/";
  public static calculateLostInterest = "due-dates/calculate-lost-interest";
  public static dueDates = "due-dates";
  public static pesQuotationURI = "pes-quotation-api/";
  public static pesQuotes = "pes-quotes";
  public static calculateQuote = "/calculate-quote";
  public static modagBaseURI = "modag-api/";
  public static modalList = "modags";
  public static calculateModagQuotes = "modags/calculate-modag-quote";
  public static addressSearch = "addresses/address-search";
  public static credicBureauURI = "credit-bureau-api/";
  public static credicBureauSearch = "search";
  public static v2URI = "v2";
  public static mailPreference = "/mail-preference";
  public static documentURI = 'documents-api/';
  public static budgetPlanners = "/budget-planners";
  public static documents = '/documents';
  public static endOfContractApi = 'eoc-api/';
  public static endOfCOntractURI = '/end-of-contract';
  public static dealersAPI = 'dealers';
  public static configurationURI = 'api/wss-bb-service-configuration/application/default';
  public static budgetPlannerURi = 'budget-planner-api';
  public static consentsURI = "consents";
  public static changeEmailURI = "username-change";
  public static cdfmURI = "cdfm-api";
  public static proposalURI = "proposal-api";
  public static additionalAddressURI = "additional-address";
  public static v1URI = "v1";
  public static gfvURI = 'gfv';
  public static proposals = 'proposals';
  public static submit = 'submit';
  public static comments = "comments";
  public static decision = "decision";
  public static apiProxyUrl = "api/wss-bb-service-api-proxy/";
  public static proposalApi = 'proposal-api';
  public static creditcheck = 'creditcheck';
  public static vehicleType = 'vehicle_type=';
  public static rtfUpdate = 'rtfupdate';
}

export class headerData {
  public static contentType = 'application/x-www-form-urlencoded';
}

export class patternMap {
  public static digitsAccount = '^\[0-9]{9}$';
  public static bankAccNamePattern = '^[a-zA-Z0-9\/\&\. ]+$';
  public static postCode = '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Z0-9]? ?[0-9][A-Za-z]{2}|GIR ?0A{2})$';
  public static mobileNumber = '^0?[1-9]{1}[0-9]{9}$';
  public static dobPattern = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/;
  public static dayPattern = '^0?(3[01]|[12][0-9]|[1-9])$';
  public static monthPattern = '^0?(1[0-2]|[1-9])$';
  public static yearPattern = '^19[0-9]|20[0-4][0-4]|2004$';
  public static emailPattern = (/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i); //NOSONAR
  public static amountwithtwodecimalPattern = /^(?:\d*\.\d{1,2}|\d+)$/;
  public static amountWithTwoDecimalNoZeroPattern = /^(?!0$)(?:\d*\.\d{1,2}|\d+)$/;
  public static ukMobileAndLandlinePattern = /(((\+44(0)?)|0)(1|2|7)\d{3}(\s)?\d{5,6}){1,15}$/gm;
  public static validSpecialCharsPattern = /^[-@.\/#!%^£$&¬€,\'\’\’’\״\׳\`\\״\\׳\`\_*)(+=<>{}\[\]:;?~\|\\"\w\n ]*$/;
  public static uppercasePattern = /[A-Z]+/;
  public static lowercasePattern = /[a-z]+/;
  public static numberPattern = /[0-9]+/;
  public static wholeNumberPattern = "^[0-9]*$";
  public static textboxPattern = /^[-@.\/#!%^£$&¬€,\'\’\’’\״\׳\`\\״\\׳\`\_*)(+=<>{}\[\]:;?~\|\\"\w\n ]*$/;
  public static alphanumericPattern = "^[a-zA-Z0-9 ]+$";
  public static alphanumericHypenPattern = '^[a-zA-Z0-9 -]+$'
  public static alphanumericHypenAprostrophePattern = '^[a-zA-Z0-9\-\' ]+$'
  public static alphanumericHypenForwardSlashPattern = '^[a-zA-Z0-9\-\/ ]+$';
  public static alphanumericHypenApostropheForwardSlashPattern = '^[a-zA-Z0-9\-\/\' ]+$';
  public static alphabeticPattern = '^[a-zA-Z ]+$';
  public static alphabeticApostropheHyphenPattern = '^[a-zA-Z \'-]+$';
  public static alphabeticHyphenPattern = '^[a-zA-Z -]+$';
  public static employersNamePattern = /^[A-Za-z0-9 \u2019'\-\\&@~`!$#%^*()_+={}[\]]+$/;
}

export class budgetForm {
  public static maximumAdditionInfoEntries = 10;
  public static typeOfAccount = ['Current account', 'Credit card', 'Loan', 'Mortgage', 'Vehicle finance']
  public static typeOfInsurance = ['Buildings insurance', 'Contents insurance', 'Car insurance', 'Combined insurance', 'Pet insurance', 'Life assurance', 'Other insurance'];
  public static frequencyList = ['Monthly', 'Weekly', 'Fortnightly', '4 weekly', 'Annually'];
  public static typeOfCost = ['Service charges', 'Ground rent', 'Repairs', 'Maintenance', 'Other'];
  public static typeOfIncome = ["Student loan", "Bursary", "Investment income", "Rental income", "Maintenance", "Partner and family member", "Other"];
}


export class budgetPageName {
  static personal: string = "Personal"
  static income: string = "Income";
  static livingCost: string = "Living costs";
  static spending: string = "Spending";
  static moneyyouown: string = "Money you own"

  // Accessors for "get" functions only (no "set" functions)
  static get PERSONAL() { return this.personal }
  static get INCOME() { return this.income; }
  static get LIVINGCOST() { return this.livingCost; }
  static get SPENDING() { return this.spending; }
  static get MONEYYOUOWN() { return this.moneyyouown }
}

export class currencySymbol {
  static gbp: string = "£"
  static usd: string = "$";
  static euro: string = "€";
}

export class commonOptions {
  public static yesNo =  [
    { id: 'Yes', value: 'yes', title: 'Yes' },
    { id: 'No', value: 'no', title: 'No' }
  ]
}

export class balloonRefinance {
  public static titleArray = [
    { value: 'MRS', title: 'Mrs' },
    { value: 'MR', title: 'Mr' },
    { value: 'MISS', title: 'Miss' },
    { value: 'MS', title: 'Ms' },
    { value: 'DR', title: 'Dr' },
    { value: 'REV', title: 'Rev' }
  ]

  public static nationalityArray = [
    { value: 'GB', title: 'United Kingdom' },
    { value: 'AD', title: 'Andorra' },
    { value: 'AE', title: 'Unites Arab Emirates' },
    { value: 'AF', title: 'Afghanistan' },
    { value: 'AG', title: 'Antigua & Barbuda' },
    { value: 'AI', title: 'Anguilla' },
    { value: 'AL', title: 'Albania' },
    { value: 'AM', title: 'Armenia' },
    { value: 'AO', title: 'Angola' },
    { value: 'AQ', title: 'Antartica' },
    { value: 'AR', title: 'Argentina' },
    { value: 'AS', title: 'American Samoa' },
    { value: 'AT', title: 'Austria' },
    { value: 'AU', title: 'Australia' },
    { value: 'AW', title: 'Aruba' },
    { value: 'AX', title: 'Aland Islands' },
    { value: 'AZ', title: 'Azerbaijan' },
    { value: 'BA', title: 'Bosnia & Herzogovinia' },
    { value: 'BB', title: 'Barbados' },
    { value: 'BD', title: 'Bangladesh' },
    { value: 'BE', title: 'Belgium' },
    { value: 'BF', title: 'Burkino Faso' },
    { value: 'BG', title: 'Bulgaria' },
    { value: 'BH', title: 'Bahrain' },
    { value: 'BI', title: 'Burundi' },
    { value: 'BJ', title: 'Benin' },
    { value: 'BL', title: 'Saint Barthelemy' },
    { value: 'BM', title: 'Bermuda' },
    { value: 'BN', title: 'Brunei Darussalam' },
    { value: 'BO', title: 'Bolivia' },
    { value: 'BQ', title: 'Bonaire, Saint Eustatius & Saba' },
    { value: 'BR', title: 'Brazil' },
    { value: 'BS', title: 'Bahamas' },
    { value: 'BT', title: 'Bhutan' },
    { value: 'BV', title: 'Bouvet Island' },
    { value: 'BW', title: 'Botswana' },
    { value: 'BY', title: 'Belarus' },
    { value: 'BZ', title: 'Belize' },
    { value: 'CA', title: 'Canada' },
    { value: 'CC', title: 'Cocos (Keeling) Islands' },
    { value: 'CD', title: 'Congo (Democratice Republic Of)' },
    { value: 'CF', title: 'Central African republic' },
    { value: 'CG', title: 'Congo' },
    { value: 'CH', title: 'Switzerland' },
    { value: 'CI', title: 'Cote d`Ivoire' },
    { value: 'CK', title: 'Cook Islands' },
    { value: 'CL', title: 'Chile' },
    { value: 'CM', title: 'Cameroon' },
    { value: 'CN', title: 'China' },
    { value: 'CO', title: 'Colombia' },
    { value: 'CR', title: 'Costa Rica' },
    { value: 'CU', title: 'Cuba' },
    { value: 'CV', title: 'Capo Verde' },
    { value: 'CW', title: 'Curacao' },
    { value: 'CX', title: 'Christmas Island' },
    { value: 'CY', title: 'Cyprus' },
    { value: 'CZ', title: 'Czech Republic' },
    { value: 'DE', title: 'Germany' },
    { value: 'DM', title: 'Dominica' },
    { value: 'DO', title: 'Dominican Republic' },
    { value: 'DJ', title: 'Djibouti' },
    { value: 'DK', title: 'Denmark' },
    { value: 'DZ', title: 'Algeria' },
    { value: 'EC', title: 'Ecuador' },
    { value: 'EE', title: 'Estonia' },
    { value: 'EG', title: 'Egypt' },
    { value: 'EH', title: 'Western Sahara' },
    { value: 'ER', title: 'Eritrea' },
    { value: 'ES', title: 'Spain' },
    { value: 'ET', title: 'Ethiopia' },
    { value: 'FI', title: 'Finland' },
    { value: 'FJ', title: 'Fiji' },
    { value: 'FK', title: 'Falkland Islands' },
    { value: 'FM', title: 'Micronesia' },
    { value: 'FO', title: 'Faro islands' },
    { value: 'FR', title: 'France' },
    { value: 'GA', title: 'Gabon' },
    { value: 'GB', title: 'United Kingdom' },
    { value: 'GD', title: 'Grenada' },
    { value: 'GE', title: 'Georgia' },
    { value: 'GF', title: 'French Guiana' },
    { value: 'GG', title: 'Guernsey' },
    { value: 'GH', title: 'Ghana' },
    { value: 'GI', title: 'Gibraltar' },
    { value: 'GL', title: 'Greenland' },
    { value: 'GM', title: 'Gambia' },
    { value: 'GN', title: 'Guinea' },
    { value: 'GP', title: 'Guadaloupe' },
    { value: 'GQ', title: 'Equatorial Guinea' },
    { value: 'GR', title: 'Greece' },
    { value: 'GS', title: 'South Georgia & South Sandwich Islands' },
    { value: 'GT', title: 'Guatamala' },
    { value: 'GU', title: 'Guam' },
    { value: 'GW', title: 'Guinea-Bissau' },
    { value: 'GY', title: 'Guyana' },
    { value: 'HK', title: 'Hong Kong' },
    { value: 'HM', title: 'Heard Island & McDonald Islands' },
    { value: 'HN', title: 'Honduras' },
    { value: 'HR', title: 'Croatia' },
    { value: 'HT', title: 'Haiti' },
    { value: 'HU', title: 'Hungary' },
    { value: 'ID', title: 'Indonesia' },
    { value: 'IE', title: 'Ireland' },
    { value: 'IL', title: 'Israel' },
    { value: 'IM', title: 'Isle of Man' },
    { value: 'IN', title: 'India' },
    { value: 'IO', title: 'Britich Indian Ocean Territory' },
    { value: 'IQ', title: 'Iraq' },
    { value: 'IR', title: 'Iran' },
    { value: 'IS', title: 'Iceland' },
    { value: 'IT', title: 'Italy' },
    { value: 'JE', title: 'Jersey' },
    { value: 'JM', title: 'Jamaica' },
    { value: 'JO', title: 'Jordan' },
    { value: 'JP', title: 'Japan' },
    { value: 'KE', title: 'Kenya' },
    { value: 'KG', title: 'Kyrgyzstan' },
    { value: 'KH', title: 'Cambodia' },
    { value: 'KI', title: 'Kiribati' },
    { value: 'KM', title: 'Comoras' },
    { value: 'KN', title: 'Saint Kitts & Nevis' },
    { value: 'KP', title: 'North Korea' },
    { value: 'KR', title: 'South Korea' },
    { value: 'KW', title: 'Kuwait' },
    { value: 'KY', title: 'Cayman Islands' },
    { value: 'KZ', title: 'Kazakhstan' },
    { value: 'LA', title: 'Laos' },
    { value: 'LB', title: 'Lebanon' },
    { value: 'LC', title: 'Saint Lucia' },
    { value: 'LI', title: 'Leichtenstein' },
    { value: 'LK', title: 'Sri Lanka' },
    { value: 'LR', title: 'Liberia' },
    { value: 'LS', title: 'Lesotho' },
    { value: 'LT', title: 'Lithuania' },
    { value: 'LU', title: 'Luxemburg' },
    { value: 'LV', title: 'Latvia' },
    { value: 'LY', title: 'Libya' },
    { value: 'MA', title: 'Morroco' },
    { value: 'MC', title: 'Monaco' },
    { value: 'MD', title: 'Moldova' },
    { value: 'ME', title: 'Montenegro' },
    { value: 'MF', title: 'Saint Martin' },
    { value: 'MG', title: 'Madagascar' },
    { value: 'MH', title: 'Marshall Islands' },
    { value: 'MK', title: 'Macedonia' },
    { value: 'ML', title: 'Mali' },
    { value: 'MM', title: 'Myanmar' },
    { value: 'MN', title: 'Mongolia' },
    { value: 'MO', title: 'Macao' },
    { value: 'MP', title: 'Northern Mariana Islands' },
    { value: 'MQ', title: 'Martinique' },
    { value: 'MR', title: 'Mauritania' },
    { value: 'MS', title: 'Montserrat' },
    { value: 'MT', title: 'Malta' },
    { value: 'MU', title: 'Mauritius' },
    { value: 'MV', title: 'Maldives' },
    { value: 'MW', title: 'Malawi' },
    { value: 'MX', title: 'Mexico' },
    { value: 'MY', title: 'Malaysia' },
    { value: 'MZ', title: 'Mozambique' },
    { value: 'NA', title: 'Namibia' },
    { value: 'NC', title: 'New Caledonia' },
    { value: 'NE', title: 'Niger' },
    { value: 'NF', title: 'Norfolk Island' },
    { value: 'NG', title: 'Nigeria' },
    { value: 'NI', title: 'Nicaragua' },
    { value: 'NL', title: 'Netherlands' },
    { value: 'NO', title: 'Norway' },
    { value: 'NP', title: 'Nepal' },
    { value: 'NR', title: 'Nauru' },
    { value: 'NU', title: 'Niue' },
    { value: 'NZ', title: 'New Zealand' },
    { value: 'OM', title: 'Oman' },
    { value: 'PA', title: 'Panama' },
    { value: 'PE', title: 'Peru' },
    { value: 'PF', title: 'French Polynesia' },
    { value: 'PG', title: 'Papua New Guinea' },
    { value: 'PH', title: 'Philippines' },
    { value: 'PK', title: 'Pakistan' },
    { value: 'PL', title: 'Poland' },
    { value: 'PM', title: 'Saint Pierre Miquelon' },
    { value: 'PN', title: 'Pitcairn' },
    { value: 'PR', title: 'Puerto Rico' },
    { value: 'PS', title: 'Palestine' },
    { value: 'PT', title: 'Portugal' },
    { value: 'PW', title: 'Palau' },
    { value: 'PY', title: 'Paraguay' },
    { value: 'QA', title: 'Qatar' },
    { value: 'RE', title: 'Reunion' },
    { value: 'RO', title: 'Romania' },
    { value: 'RS', title: 'Serbia' },
    { value: 'RU', title: 'Russia' },
    { value: 'RW', title: 'Rwanda' },
    { value: 'SA', title: 'Saudi Arabia' },
    { value: 'SB', title: 'Soloman Islands' },
    { value: 'SC', title: 'Seychelles' },
    { value: 'SD', title: 'Sudan' },
    { value: 'SE', title: 'Sweden' },
    { value: 'SG', title: 'Singapore' },
    { value: 'SH', title: 'St. Helena Ascension & Tristan da Cunha'},
    { value: 'SI', title: 'Slovenia' },
    { value: 'SJ', title: 'Svalbard & Janayen' },
    { value: 'SK', title: 'Slovakia' },
    { value: 'SL', title: 'Sierra Leone' },
    { value: 'SM', title: 'San Marino' },
    { value: 'SN', title: 'Senegal' },
    { value: 'SO', title: 'Somalia' },
    { value: 'SR', title: 'Suriname' },
    { value: 'SS', title: 'South Sudan' },
    { value: 'ST', title: 'Sao Tome & Principe' },
    { value: 'SV', title: 'El Salvador' },
    { value: 'SZ', title: 'Sint Maarten' },
    { value: 'SY', title: 'Syria' },
    { value: 'SZ', title: 'Swaziland' },
    { value: 'TC', title: 'Turks & Caicos Islands' },
    { value: 'TD', title: 'Chad' },
    { value: 'TF', title: 'French Southern Territories' },
    { value: 'TG', title: 'Togo' },
    { value: 'TH', title: 'Thailand' },
    { value: 'TJ', title: 'Tajikistan' },
    { value: 'TK', title: 'Tokelau' },
    { value: 'TL', title: 'Timor-Leste' },
    { value: 'TM', title: 'Turkmenistan' },
    { value: 'TN', title: 'Tunisia' },
    { value: 'TO', title: 'Tonga' },
    { value: 'TR', title: 'Turkey' },
    { value: 'TT', title: 'Trinidad & Tobago' },
    { value: 'TV', title: 'Tuvalu' },
    { value: 'TW', title: 'Taiwan' },
    { value: 'TZ', title: 'Tanzania' },
    { value: 'UA', title: 'Ukraine' },
    { value: 'UG', title: 'Uganda' },
    { value: 'UM', title: 'United States Minor Outlying Islands' },
    { value: 'US', title: 'United States of America' },
    { value: 'UY', title: 'Uruguay' },
    { value: 'UZ', title: 'Uzbekistan' },
    { value: 'VA', title: 'Holy See' },
    { value: 'VC', title: 'Saint Vincent & Grenadines' },
    { value: 'VE', title: 'Venezuela' },
    { value: 'VG', title: 'Virgin Islands (British)' },
    { value: 'VI', title: 'Virgin Islands (U.S.)' },
    { value: 'VN', title: 'Vietnam' },
    { value: 'VU', title: 'Vanuatu' },
    { value: 'WF', title: 'Wallis & Futuna' },
    { value: 'WS', title: 'Samoa' },
    { value: 'YE', title: 'Yemen' },
    { value: 'YT', title: 'Mayotte' },
    { value: 'ZA', title: 'South Africa' },
    { value: 'ZM', title: 'Zambia' },
    { value: 'ZW', title: 'Zimbabwe' }
  ]
}

export class curmMemoText {
  public static webservBR = 'WEBSERV Balloon Refinance ';
  public static proposalNo = 'Proposal No ';
  public static created = 'Created ';
  public static lendingReferred = 'Lending Referred ';
  public static lendingApproved = 'Lending Approved';
  public static lendingDeclined = 'Lending Declined'
  public static modagSigned = 'Mod Ag Signed Online';
  public static date = 'Date ';
}

export class vtMemoText {
  public static webservRequest = 'WEBSERV Voluntary Termination';
  public static vtPackRequested = 'pack requested on Web Self-serve';
  public static date = 'Date ';
}

export class brUrls {
  public static getAQuote: string = "#/balloon-refinance/get-a-quote";
  public static vehicle: string = "#/balloon-refinance-getdetails/vehicle";
  public static aboutYou: string = "#/balloon-refinance-getdetails/about-you";
  public static employment: string = "#/balloon-refinance-getdetails/employment";
  public static finances: string = "#/balloon-refinance-getdetails/finances";
  public static bankAccount: string = "#/balloon-refinance-getdetails/bank-account";
  public static summary: string = "#//balloon-refinance-getdetails/summary";
  public static ctaOutOfHours: string = "#/balloon-refinance/cta-out-of-hours";
  public static ctaArrears: string = "#/balloon-refinance/cta-arrears";
  public static ctaExpriedEocPeriod: string = "#/balloon-refinance/cta-expired-eoc-period";
  public static ctaDDCalling: string = "#/balloon-refinance/cta-dd-calling"
  public static accepted: string = "#/balloon-refinance-getdetails/accept";
  public static referred: string = "#/balloon-refinance-getdetails/referral";
  public static declined: string = "#/balloon-refinance-getdetails/decline";
}

export class proposalStatus {
  public static declined: string = "DECLINED";
  public static referred: string = "REFERRED";
  public static accepted: string = "ACCEPTED";
  public static incomplete: string = "INCOMPLETE";
}

export class vehicleTypes {
  public static car: string = "C";
  public static bike: string = "B";
}

export class creditcheckPlansDev {
  public static carDealer = "WB2";
  public static bikeDealer = "WB1"
}

export class creditcheckPlansQa {
  public static carDealer = "WB4";
  public static bikeDealer = "WB3"
}

export class creditcheckPlansProd {
  public static carDealer = "A1L";
  public static bikeDealer = "A1K";
}
