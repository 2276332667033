import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { apiURI, RuntimeEnv } from '../config/wss-app-constants';
import { DatastoreService } from './datastore.service';
import { RequestService } from './request.service';
import { Account } from '../model/account';
import { messageOTP } from '../model/messageOtp';
import { Transaction } from '../transaction-widget/src/model/transactions';
import {
  getHeadersRegister,
  getHeaders,
  getHeadersLogin,
  getHeadersPayment,
  getCompanyNumber,
} from '../common/util/header-util';
import { BankAccount, DirectDebit, DirectDebitResponse } from '../payment-widget/src/models/direct-debit';
import { QuestionIIQ } from '../model/questionIIQ';
import { FwdPwd, LinkValid } from '../model/forgotPassword';
import { CustomerToken, userToken, OTPVerify, ForgotUsernameOTPVerify } from '../model/registrationToken';
import {
  Card,
  CardPaymentDetail,
  PayPageResponseDetail,
} from '../payment-widget/src/models/make-payment';
import { RegisterDatastoreService } from '../registration-widget/src/service/register-datastore.service';
import { DueDate } from '../payment-widget/src/models/change-payment';
import { ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private httpClient: HttpClient,
    private datastoreService: DatastoreService,
    private registerdatastoreService: RegisterDatastoreService,
    private requestService: RequestService
  ) { }

  getStaticContent(contenttxt: string, contentfolder: string): Observable<any[]> {
    let apiURL: string = '';
    if (RuntimeEnv.backbase) {
      let portalName: string = window.BB.config.portalName;
      if (contenttxt === 'over-payment-landing') {
        apiURL = apiURI.cmnContentURI + contentfolder + '/' + contenttxt + '.json';
      } else {
        apiURL = apiURI.baseURI + apiURI.contentURI + portalName + '/content/' + contentfolder + '/' + contenttxt + '.json';
      }
    } else {
      apiURL = apiURI.localContentURI + contenttxt + '.json';
    }
    return this.httpClient.get<any>(apiURL).pipe(
      catchError((error) => {
        let apiCmnURL: string = apiURI.cmnContentURI + contentfolder + '/' + contenttxt + '.json';
        return this.httpClient.get<any>(apiCmnURL);
      }),
    );
  }

  getAuthorisation(): Observable<HttpResponse<any>> {
    let apiURL: string = '';
    if (this.datastoreService.getPwdReset() === true) {
      apiURL = apiURI.baseURI + apiURI.accountMngURI + apiURI.authURI;
    } else {
      apiURL = apiURI.baseURI + apiURI.regURI + apiURI.authURI;
    }
    let headers = getHeadersRegister(false, '', true);
    const options = {
      headers: headers,
      observe: 'response' as 'body',
    };
    return this.httpClient.get<any>(apiURL, options);
  }

  getCustomers(): Observable<CustomerToken[]> {
    let paramQuery = this.registerdatastoreService.getParamCust();
    let headers = getHeadersRegister(true, '', false);
    let apiURL: string = apiURI.baseURI + apiURI.regURI + apiURI.customerURI + '?' + paramQuery;
    return this.httpClient.get<CustomerToken[]>(apiURL, { headers });
  }

  getCustomersData(dob: string, postcode: string): Observable<any> {
    let headers = getHeadersRegister(true, '', false);
    let apiURL: string = apiURI.baseURI + apiURI.regisURIDecrypt + apiURI.customerURI + '?date_of_birth='+ dob + "&post_code=" + postcode;
    return this.httpClient.get<any>(apiURL, { headers });
  }

  getCustomersMultiple(): Observable<any> {
    let headers = getHeadersRegister(true, '', false);
    let apiURL: string = apiURI.baseURI + apiURI.regURI + apiURI.customerURI + '/' + apiURI.verifyPwdURI;
    let reqBody = this.requestService.getMultiCustReq();
    return this.httpClient.post<any>(apiURL, reqBody, { headers });
  }

  getCustomerDetails(): Observable<userToken> {
    let headers = getHeadersRegister(true, '', false);
    let apiURL: string =
      apiURI.baseURI + apiURI.regURI + apiURI.customerURI + '/' + this.registerdatastoreService.getCustToken();
    return this.httpClient.get<userToken>(apiURL, { headers });
  }

  getCustomerOTP(): Observable<messageOTP> {
    let apiURL: string = apiURI.baseURI + apiURI.regURI + apiURI.customerOTPURI;
    let headers = getHeadersRegister(true, '', false);
    let reqBody = this.requestService.getReqSendOptions();
    return this.httpClient.post<messageOTP>(apiURL, reqBody, { headers });
  }

  getForgotEmailOTP(): Observable<messageOTP> {
    let apiURL: string = apiURI.baseURI + apiURI.regURI + apiURI.forgotEmailOTPURI;
    let headers = getHeadersRegister(true, '', false);
    let reqBody = this.requestService.getReqSendOptions();
    return this.httpClient.post<messageOTP>(apiURL, reqBody, { headers });
  }

  getCheckOTP(): Observable<OTPVerify> {
    let apiURL: string =
      apiURI.baseURI +
      apiURI.regURI +
      apiURI.usersURI +
      this.registerdatastoreService.getUserToken() +
      '/' +
      apiURI.verifyPwdURI;
    let headers = getHeadersRegister(true, '', false);
    let reqBody = this.requestService.getReqVerifyOTP();
    return this.httpClient.post<OTPVerify>(apiURL, reqBody, { headers });
  }

  getCheckEmailOTP(): Observable<ForgotUsernameOTPVerify> {
    let apiURL: string =
      apiURI.baseURI +
      apiURI.regURI +
      apiURI.forgotUsernameURI +
      this.registerdatastoreService.getUserToken() +
      '/' +
      apiURI.verifyPwdURI
    let headers = getHeadersRegister(true, '', false);
    let reqBody = this.requestService.getReqVerifyOTP();
    return this.httpClient.post<ForgotUsernameOTPVerify>(apiURL, reqBody, { headers });
  }

  getCustomerConfirm(): Observable<any> {
    let reqBody: any = '';
    let headers = getHeadersRegister(true, '', false);
    let apiURL: string =
      apiURI.baseURI +
      apiURI.regURI +
      apiURI.usersURI +
      this.registerdatastoreService.getUserToken() +
      apiURI.confirmURI;
    if (this.registerdatastoreService.getVerifyType() === 'OTP') {
      reqBody = this.requestService.getOTPConfirm();
    } else {
      reqBody = this.requestService.getIIQConfirm();
    }
    return this.httpClient.post<any>(apiURL, reqBody, { headers });
  }

  getQuestionIIQ(): Observable<QuestionIIQ> {
    let headers = getHeadersRegister(true, '', false);
    let apiURL: string = apiURI.baseURI + apiURI.regURI + apiURI.userURI + '?registration_option=IIQ';
    let reqBody = this.requestService.getReqSendOptions();
    return this.httpClient.post<QuestionIIQ>(apiURL, reqBody, { headers });
  }

  getQuestionIIQVerify(): Observable<any> {
    let headers = getHeadersRegister(true, '', false);
    let apiURL: string =
      apiURI.baseURI +
      apiURI.regURI +
      apiURI.usersURI +
      this.registerdatastoreService.getUserToken() +
      apiURI.confirmURI;
    let reqBody = this.requestService.getReqIIQ();
    return this.httpClient.post<any>(apiURL, reqBody, { headers });
  }

  getLogout(): Observable<any> {
    let apiURL: string = 'api/wss-bb-service-login/logout';
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers });
  }

  login(email: string, password: string): Observable<any> {
    let apiURL: string = apiURI.baseURI + apiURI.loginURI;
    let bodyResponse = 'username=' + email + '&password=' + password;
    let headers = getHeadersLogin();
    const options = {
      headers: headers,
      observe: 'response' as 'body',
    };
    return this.httpClient.post<any>(apiURL, bodyResponse, options);
  }

  loginDecrypt(email: string): Observable<any> {
    let apiURL: string = apiURI.baseURI + apiURI.loginURIDecrypt + '?email=' + email;
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers });
  }

  getCustomerId(): Observable<any> {
    let userName = this.datastoreService.getUserName() ? this.datastoreService.getUserName() : this.datastoreService.getLoggedInEmail();
    let companyNumber = getCompanyNumber();
    let apiURL: string =
      apiURI.baseURI + apiURI.accURI + apiURI.userURI + '?company_number=' + companyNumber + '&alias=' + userName;
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers });
  }

  getPwdLink(value: string): Observable<FwdPwd> {
    let apiURL: string = apiURI.baseURI + apiURI.accountMngURI + apiURI.resetPwdURI + '/' + apiURI.resetPwdURIGetLink;
    let headers = getHeaders(true, '', false);
    let body = {
      alias : value,
    }
    return this.httpClient.post<FwdPwd>(apiURL, body, { headers });
  }

  validatePwdLink(): Observable<LinkValid> {
    let apiURL: string = apiURI.baseURI + apiURI.accountMngURI + apiURI.resetPwdURI + '/' + apiURI.verifyPwdURI;
    let getToken = this.datastoreService.getFwdPwdToken();
    let body = {
      password_reset_token: getToken,
    };
    let headers = getHeaders(true, '', false);
    return this.httpClient.post<LinkValid>(apiURL, body, { headers });
  }

  confirmPwdLink(value: string, passwordToken?: boolean): Observable<LinkValid> {
    let apiURL: string = apiURI.baseURI + apiURI.accountMngURI + apiURI.resetPwdURI;
    let getToken = this.datastoreService.getFwdPwdToken();
    let body: any = {};
    if (!passwordToken) {
      body = {
        password: value,
      };
    } else {
      body = {
        password: value,
        password_reset_token: getToken,
      };
    }
    let headers = getHeaders(true, '', false);
    return this.httpClient.post<LinkValid>(apiURL, body, { headers });
  }

  checkPwd(password: string): Observable<LinkValid> {
    let apiURL: string = apiURI.baseURI + apiURI.accountMngURI + apiURI.checkPwdURI;
    let getUsername = this.datastoreService.getLoggedInEmail();
    let body: any = {
      username: getUsername,
      password: password
    };
    let headers = getHeaders(true, '', false);
    return this.httpClient.post<LinkValid>(apiURL, body, { headers });
  }

  getCustomerDetail(customerId: string): Observable<any> {
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.customerURI + '/' + customerId;
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers });
  }

  getAccounts(): Observable<Account> {
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.accountsURI;
    let headers = getHeaders(true, this.datastoreService.getCustomerId(), false);
    return this.httpClient.get<Account>(apiURL, { headers });
  }

  getAccountDetails(): Observable<any> {
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.accountsURI + '/' + accNo;
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers });
  }

  getTransactions(noOfTransactions: number, lastSequenceNo: number): Observable<Transaction[]> {
    let headers = getHeaders(true, '', false);
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI +
      apiURI.accURI +
      apiURI.transactionBaseURI +
      accNo +
      '/' +
      apiURI.transactionsURI +
      '?no_of_trans_to_return=' +
      noOfTransactions +
      '&last_sequence_number=' +
      lastSequenceNo;
    return this.httpClient.get<Transaction[]>(apiURL, { headers });
  }

  getRequestedStatement(): Observable<any> {
    let headers = getHeaders(true, '', false);
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI + apiURI.accURI + apiURI.transactionBaseURI + accNo + '/' + apiURI.statementsURI;
    return this.httpClient.get<any>(apiURL, { headers });
  }

  requestStatement(): Observable<any> {
    let headers = getHeaders(true, '', false);
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI + apiURI.accURI + apiURI.transactionBaseURI + accNo + '/' + apiURI.statementsURI;
    return this.httpClient.post<any>(apiURL, null, { headers });
  }

  addOrUpdateDirectDebit(directDebitRequest: DirectDebit): Observable<DirectDebitResponse> {
    let headers = getHeaders(true, '', false);
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI + apiURI.accURI + apiURI.directDebitBaseURI + accNo + '/' + apiURI.directDebitsURI;
    return this.httpClient.post<DirectDebitResponse>(apiURL, directDebitRequest, { headers });
  }

  getBankAccountDetail(directDebitRequest: any): Observable<BankAccount> {
    let headers = getHeaders(true, '', false);
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI +
      apiURI.accURI +
      apiURI.directDebitBaseURI +
      accNo +
      '/' +
      apiURI.bankAccountsURI +
      '?bank_sortcode=' +
      directDebitRequest.bank_sortcode +
      '&bank_accno=' +
      directDebitRequest.bank_accno +
      '&bank_acc_holdername=' +
      directDebitRequest.bank_acc_holdername +
      '&borrower_indicator=' +
      directDebitRequest.borrower_indicator +
      '&payment_indicator=' +
      directDebitRequest.payment_indicator;
    return this.httpClient.get<BankAccount>(apiURL, { headers });
  }
  // payment api
  getCardsDetail(): Observable<Card> {
    let companyNumber = getCompanyNumber();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.cardURI + '/' + this.datastoreService.getAccountNo();
    let headerData = {
      customerId: this.datastoreService.getCustomerId,
      companyNumber: companyNumber,
      accountNumber: this.datastoreService.getAccountNo(),
    };
    let headers = getHeadersPayment(true, headerData, false);
    return this.httpClient.get<Card>(apiURL, { headers });
  }

  postCardPaymentDetail(): Observable<CardPaymentDetail> {
    let companyNumber = getCompanyNumber();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.cardPaymentURI;
    let headerData = {
      customerId: this.datastoreService.getCustomerId,
      companyNumber: companyNumber,
    };
    let payLoadBody = this.requestService.getPostCardPaymentReqBody();
    let headers = getHeadersPayment(true, headerData, false);
    return this.httpClient.post<CardPaymentDetail>(apiURL, payLoadBody, { headers });
  }

  sendEmailConfirmation(): Observable<any> {
    let companyNumber = getCompanyNumber();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.emailURI;
    let headerData = {
      customerId: this.datastoreService.getCustomerId,
      companyNumber: companyNumber,
    };
    let payLoadBody = this.requestService.getPostPaymentEmailReqBody();
    let headers = getHeadersPayment(true, headerData, false);
    return this.httpClient.post<any>(apiURL, payLoadBody, { headers });
  }

  sendEmailNotification(templateObj: any): Observable<any> {
    let companyNumber = getCompanyNumber();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.emailURI;
    let headerData = {
      customerId: this.datastoreService.getCustomerId,
      companyNumber: companyNumber,
    };
    let payLoadBody = this.requestService.geEmailRequestBody(templateObj);
    let headers = getHeadersPayment(true, headerData, false);
    return this.httpClient.post<any>(apiURL, payLoadBody, { headers });
  }

  getSettlementDetail(): Observable<any> {
    let headers = getHeaders(true, '', false);
    let accNumber = this.datastoreService.getAccountNo();
    let settlementApiURL: string =
      apiURI.baseURI +
      apiURI.accURI +
      apiURI.settlementsApiURI +
      apiURI.accountsURI +
      '/' +
      accNumber +
      '/' +
      apiURI.settlementsURI;
    return this.httpClient.get<any>(settlementApiURL, { headers });
  }

  postSettlementAmount(newSettlementFlag?: any): Observable<any> {
    let headers = getHeaders(true, '', false);
    let accNumber = this.datastoreService.getAccountNo();
    let settlementApiURL: string =
      apiURI.baseURI +
      apiURI.accURI +
      apiURI.settlementsApiURI +
      apiURI.accountsURI +
      '/' +
      accNumber +
      '/' +
      apiURI.settlementsURI;
    let reqBody = this.requestService.setSettlementDetails(newSettlementFlag);
    return this.httpClient.post<any>(settlementApiURL, reqBody, { headers });
  }

  sendSMSConfirmation(templateObj: any) {
    let companyNumber = getCompanyNumber();
    let smsConfirmationBody = this.requestService.getSmsConfirmation(templateObj);
    let smsConfirmationURI: string = apiURI.baseURI + apiURI.accURI + apiURI.smsURI;
    let headerData = {
      customerId: this.datastoreService.getCustomerId,
      companyNumber: companyNumber,
    };
    let headers = getHeadersPayment(true, headerData, false);
    return this.httpClient.post<any>(smsConfirmationURI, smsConfirmationBody, { headers });
  }

  updateContactDetail(updateRequestData: any): Observable<any> {
    let customerId = this.datastoreService.getCustomerId();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.customersURI + '/' + customerId;
    let headerData = {
      customerId: customerId,
    };
    let obj = updateRequestData;
    let headers = getHeadersPayment(true, headerData, false);
    return this.httpClient.put<any>(apiURL, obj, { headers });
  }

  getUserAddress(): Observable<any> {
    let customerId = this.datastoreService.getCustomerId();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.customersURI + '/' + customerId;
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers });
  }

  updateUserAddress(updateRequestData: any): Observable<any> {
    let customerId = this.datastoreService.getCustomerId();
    let apiURL: string =
      apiURI.baseURI + apiURI.accURI + apiURI.customersURI + '/' + customerId + '/' + apiURI.addressesURI;
    let headerData = {
      customerId: customerId,
    };
    let headers = getHeadersPayment(true, headerData, false);
    return this.httpClient.post<any>(apiURL, updateRequestData, { headers });
  }
  calculateLostInterest(newDueDate: string): Observable<DueDate> {
    let headers = getHeaders(true, '', false);
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI +
      apiURI.accURI +
      apiURI.changePaymentBaseURI +
      accNo +
      '/' +
      apiURI.calculateLostInterest +
      '?new_due_day=' +
      newDueDate;
    return this.httpClient.get<DueDate>(apiURL, { headers });
  }

  dueDates(newDueDate: DueDate): Observable<DueDate> {
    let headers = getHeaders(true, '', false);
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI +
      apiURI.accURI +
      apiURI.changePaymentBaseURI +
      accNo +
      '/' +
      apiURI.dueDates +
      '?new_due_day=' +
      newDueDate.new_due_day;
    return this.httpClient.post<DueDate>(apiURL, newDueDate, { headers });
  }

  updatePaymentResultDetails(postResult: any): Observable<PayPageResponseDetail> {
    let companyNumber = getCompanyNumber();
    let cardPaymentDetails: any = localStorage.getItem('cardPaymentsResponse');
    cardPaymentDetails = JSON.parse(cardPaymentDetails);
    let apiURL: string =
      apiURI.baseURI + apiURI.accURI + apiURI.cardPaymentURI + '/' + cardPaymentDetails.guid;
    let headerData = {
      customerId: this.datastoreService.getCustomerId,
      companyNumber: companyNumber,
    };
    let headers = getHeadersPayment(true, headerData, false);
    return this.httpClient.put<PayPageResponseDetail>(apiURL, postResult, { headers });
  }

  getOverpaymentResponse(): Observable<any> {
    let accNumber = this.datastoreService.getAccountNo();
    let payLoadBody = this.requestService.getOverpaymentReqBody();
    let headers = getHeadersPayment(true, '', false);
    let overpaymentApiURL: string =
      apiURI.baseURI +
      apiURI.accURI +
      apiURI.pesQuotationURI +
      apiURI.accountsURI +
      '/' +
      accNumber +
      '/' +
      apiURI.pesQuotes +
      apiURI.calculateQuote +
      '?pes_value=' +
      payLoadBody.pes_value +
      '&pes_date=' +
      payLoadBody.pes_date +
      '&paid_already=' +
      payLoadBody.paid_already +
      '&payment_or_term=' +
      payLoadBody.payment_or_term;
    return this.httpClient.get<any>(overpaymentApiURL, { headers });
  }

  postOverpaymentResponse(): Observable<any> {
    let accNumber = this.datastoreService.getAccountNo();
    let payLoadBody = this.requestService.getOverpaymentReqBody();
    let headers = getHeadersPayment(true, '', false);
    let overpaymentApiURL: string =
      apiURI.baseURI +
      apiURI.accURI +
      apiURI.pesQuotationURI +
      apiURI.accountsURI +
      '/' +
      accNumber +
      '/' +
      apiURI.pesQuotes;
    return this.httpClient.post<any>(overpaymentApiURL, payLoadBody, { headers });
  }

  getExistingModags(): Observable<any> {
    let headers = getHeaders(true, '', false);
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI + apiURI.accURI + apiURI.modagBaseURI + apiURI.accountsURI + '/' + accNo + '/' + apiURI.modalList;
    return this.httpClient.get<any>(apiURL, { headers });
  }

  caculateModagQuote(requestedRental: any, requestedTerm: any): Observable<any> {
    let headers = getHeaders(true, '', false);
    let accNo = this.datastoreService.getAccountNo();
    let customerType = 'B';
    let apiURL =
      apiURI.baseURI +
      apiURI.accURI +
      apiURI.modagBaseURI +
      apiURI.accountsURI +
      '/' +
      accNo +
      '/' +
      apiURI.calculateModagQuotes +
      '?modag_Type=' +
      customerType +
      '&requested_Term=' +
      requestedTerm;
    return this.httpClient.get<any>(apiURL, { headers });
  }

  submitModagQuote(requestData: any): Observable<any> {
    let headers = getHeaders(true, '', false);
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI + apiURI.accURI + apiURI.modagBaseURI + apiURI.accountsURI + '/' + accNo + '/' + apiURI.modalList;
    return this.httpClient.post<any>(apiURL, requestData, { headers });
  }

  creditBureauSearch(): Observable<any> {
    let companyNumber = getCompanyNumber();
    let headers = getHeaders(true, '', false);
    let bureauRequest: any = {
      customer_id: this.datastoreService.getCustomerId(),
      company_number: companyNumber,
      account_number: this.datastoreService.getAccountNo(),
    };
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.credicBureauURI + apiURI.credicBureauSearch;
    return this.httpClient.post<any>(apiURL, bureauRequest, { headers });
  }

  addressSearch(data: any): Observable<any> {
    let headers = getHeaders(true, '', false);
    let customerId = this.datastoreService.getCustomerId();
    let custom = 'post_code=' + data.post_code + '&' + 'house_name=' + data.house_name;
    let apiURL: string =
      apiURI.baseURI +
      apiURI.accURI +
      apiURI.customersURI +
      '/' +
      customerId +
      '/' +
      apiURI.addressSearch +
      '?' +
      custom;
    return this.httpClient.get<any>(apiURL, { headers });
  }

  getMailPreference(accountNo: any): Observable<any> {
    let accNo = accountNo || this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI + apiURI.accURI + apiURI.documentURI + apiURI.accountsURI + '/' + accNo + apiURI.mailPreference;
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers });
  }

  saveMailPreference(accNo: any, body: any): Observable<any> {
    let apiURL: string =
      apiURI.baseURI + apiURI.accURI + apiURI.documentURI + apiURI.accountsURI + '/' + accNo + apiURI.mailPreference;
    let headers = getHeaders(true, '', false);
    return this.httpClient.put<any>(apiURL, body, { headers });
  }

  updateMailboxMsgPreference(prefReq: any, guidNo: string): Observable<any> {
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI +
      apiURI.accURI +
      apiURI.documentURI +
      apiURI.accountsURI +
      '/' +
      accNo +
      apiURI.documents +
      '/' +
      guidNo;
    let headers = getHeaders(true, '', false);
    let body = prefReq;
    return this.httpClient.put<any>(apiURL, body, { headers });
  }

  downloadDocument(guidNo: string): Observable<any> {
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.documentURI + apiURI.accountsURI + '/' + accNo + apiURI.documents + '/' + guidNo;
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers });
  }

  getMailboxMsg(): Observable<any> {
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI + apiURI.accURI + apiURI.documentURI + apiURI.accountsURI + '/' + accNo + apiURI.documents;
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers });
  }

  saveEocOption(): Observable<any> {
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI +
      apiURI.accURI +
      apiURI.endOfContractApi +
      apiURI.accountsURI +
      '/' +
      accNo +
      apiURI.endOfCOntractURI;
    let headers = getHeaders(true, '', false);
    let payload = this.requestService.getEocOption();
    return this.httpClient.put<any>(apiURL, payload, { headers });
  }

  setRTF(flag: string): Observable<any> {
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string =
      apiURI.baseURI +
      apiURI.accURI +
      apiURI.endOfContractApi +
      apiURI.accountsURI +
      '/' +
      accNo +
      '/' +
      apiURI.rtfUpdate +
      '/' +
      flag
    let headers = getHeaders(true, '', false);
    return this.httpClient.put<any>(apiURL, { headers });
  }

  getDealerDetails(dealerNumber: any): Observable<any> {
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.dealersAPI + '/' + dealerNumber;
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers });
  }

  getApplicationDefaultConfiguration(): Observable<any> {
    let apiURL: string = apiURI.baseURI + apiURI.configurationURI;
    return this.httpClient.get<any>(apiURL);
  }

  getBudgetList(accNo: string): Observable<any> {
    let headers = getHeadersRegister(true, '', false);
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.budgetPlannerURi + '/' + apiURI.accountsURI + '/' + accNo + '/budget-planners';
    return this.httpClient.get<any>(apiURL, { headers });
  }

  getBudgetDetailById(accNo: string, budgetId: number): Observable<any> {
    let headers = getHeadersRegister(true, '', false);
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.budgetPlannerURi + '/' + apiURI.accountsURI + '/' + accNo + apiURI.budgetPlanners + apiURI.baseURI + budgetId;
    return this.httpClient.get<CustomerToken[]>(apiURL, { headers });
  }

  saveBudgetDetail(accNo: string, requestFormBody: any): Observable<any> {
    let headers = getHeadersRegister(true, '', false);
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.budgetPlannerURi + '/' + apiURI.accountsURI + '/' + accNo + apiURI.budgetPlanners;
    return this.httpClient.post<any>(apiURL, requestFormBody, { headers });
  }

  updateBudgetDetail(accNo: string, budgetId: number, requestFormBody: any): Observable<any> {
    let headers = getHeadersRegister(true, '', false);
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.budgetPlannerURi + '/' + apiURI.accountsURI + '/' + accNo + apiURI.budgetPlanners + apiURI.baseURI + budgetId;
    return this.httpClient.put<any>(apiURL, requestFormBody, { headers });
  }

  submitBudgetForm(accNo: string, budgetId: number,): Observable<any> {
    let headers = getHeadersRegister(true, '', false);
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.budgetPlannerURi + '/' + apiURI.accountsURI + '/' + accNo + apiURI.budgetPlanners + apiURI.baseURI + budgetId + '/' + 'submit';
    return this.httpClient.post<any>(apiURL, {}, { headers });
  }

  sendEmail(templateObj: any): Observable<any> {
    let companyNumber = getCompanyNumber();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.emailURI;

    let headerData = {
      customerId: this.datastoreService.getCustomerId,
      companyNumber: companyNumber,
    };
    let headers = getHeadersPayment(true, headerData, false);

    return this.httpClient.post<any>(apiURL, templateObj, { headers });
  }

  fetchOverpaymentResponse() {
    let accNumber = this.datastoreService.getAccountNo();
    let overpaymentApiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.pesQuotationURI + apiURI.accountsURI + '/' + accNumber + '/' + apiURI.pesQuotes;
    let payloadBody = this.requestService.getOverpaymentReqBody();
    let authHeaders = getHeaders(true, '', false);
    fetch(overpaymentApiURL,
      {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          ...authHeaders
        },
        body: JSON.stringify(payloadBody)
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  postCustomerConsents(): Observable<any> {
    let reqBody: any = {
      concent_type: "IOFFSET"
    };
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.accountsURI + '/' + accNo + '/' + apiURI.consentsURI;
    let headers = getHeaders(true, '', false);
    return this.httpClient.post<any>(apiURL, reqBody, { headers });
  }

  sendSettlementQuoteEmail(settlement_figure: any): Observable<any> {
    let companyNumber = getCompanyNumber();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.emailURI;
    let headerData = {
      customerId: this.datastoreService.getCustomerId,
      companyNumber: companyNumber,
    };
    let payLoadBody = this.requestService.getSettlementEmailReqBody(settlement_figure);
    let headers = getHeadersPayment(true, headerData, false);
    return this.httpClient.post<any>(apiURL, payLoadBody, { headers });
  }

  sendSettlementQuote3rdPartyEmail(settlement_figure: any, thirdPartyEmail: any): Observable<any> {
    let companyNumber = getCompanyNumber();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.emailURI;
    let headerData = {
      customerId: this.datastoreService.getCustomerId,
      companyNumber: companyNumber,
    };
    let payLoadBody = this.requestService.getSettlementEmail3rdPartyReqBody(settlement_figure, thirdPartyEmail);
    let headers = getHeadersPayment(true, headerData, false);
    return this.httpClient.post<any>(apiURL, payLoadBody, { headers });
  }

  updateEmailAlias(updateRequestData: any): Observable<any> {
    let apiURL: string = apiURI.baseURI + apiURI.accountMngURI + apiURI.changeEmailURI;
    let obj = updateRequestData;
    let headers = getHeaders(true, '', false);
    return this.httpClient.post<any>(apiURL, obj, { headers });
  }

  updateUserDetails(updateRequestData: any): Observable<any> {
    let userId = updateRequestData['user_Id'] ? updateRequestData['user_Id'] : updateRequestData['user_id'];
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.userURI + '/' + userId + '?alias=' + updateRequestData['alias'] + '&emailUpdateDate=' + updateRequestData['date_Email_Updated'] + '&isEmailUpdated=' + updateRequestData['is_Email_Updated'] + '&USER_ID=' + userId + '&marketingDate=' + updateRequestData['date_Marketing'] + '&isMarketingFlag=' + updateRequestData['marketing_Flag'] + '&marketingDisplay=' + updateRequestData['marketing_Display'];
    let headerData = {
      customerId: this.datastoreService.getCustomerId,
    };
    let obj = updateRequestData;
    let headers = getHeadersPayment(true, headerData, false);
    return this.httpClient.put<any>(apiURL, obj, { headers });
  }

  saveContactPreference(reqObj: any, isRegistrationFlow: boolean): Observable<any> {
    let cdfmURL: string = apiURI.baseURI + apiURI.accURI + apiURI.cdfmURI + '/UpdateCDFMMarketingPreferences';
    let registrationURL: string = apiURI.baseURI + apiURI.regURI + 'updateCDFMMarketingPreferences';
    let apiURL: string = isRegistrationFlow ? registrationURL : cdfmURL;
    let headers = getHeadersRegister(true, '', false);
    return this.httpClient.post<any>(apiURL, reqObj, { headers });
  }

  getContactPreference(accNo: any, clientKey: any) {
    let headers = getHeadersRegister(true, '', false);
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.cdfmURI + '/GetCDFMMarketingPreferencesByAccountNumber?AccountNumber=' + accNo + '&ClientKey=' + clientKey;
    return this.httpClient.get<any>(apiURL, { headers });
  }

  //Balloon refinance dummy API
  getUserDetails(): Observable<any> {
    return this.httpClient.get("http://localhost:3000/userDetails");
  }

  getVehicleById(id: string): Observable<any> {
    return this.httpClient.get("http://localhost:3000/vehicle/" + id);
  }


  postProposalDetails(updateResProposal: ProposalRequest): Observable<any> {
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.proposalURI;
    let headers = getHeaders(true, '', false);
    return this.httpClient.post<any>(apiURL, updateResProposal, { headers });
  }

  getProposalDetails(): Observable<any> {
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.proposalURI +'/'+ accNo;
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers });
  }

  getAdditionalAddress() {
    let getCustomer = this.datastoreService.getCustomerId();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.customerURI + '/' + getCustomer + '/' + apiURI.addressesURI + '/' + apiURI.additionalAddressURI;
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers });
  }

  postAdditionalAddress(reqAddress: any): Observable<any> {
    let getCustomer = this.datastoreService.getCustomerId();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.customerURI + '/' + getCustomer + '/' + apiURI.addressesURI + '/' + apiURI.additionalAddressURI;
    let headers = getHeaders(true, '', false);
    return this.httpClient.post<any>(apiURL, reqAddress, { headers });
  }

  postCustomerComments(payload: any) {
    let getCustomer = this.datastoreService.getCustomerId();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.customersURI + '/' + getCustomer + '/' + apiURI.comments
    let headers = getHeaders(true, '', false);
    return this.httpClient.post<any>(apiURL, payload, { headers });
  }

  getGfv(params: any) {
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.proposalApi + '/' + apiURI.gfvURI + "?cap_code=" + params.cap_code + "&goods_code=" + params.goods_code
      + "&product=" + params.product + "&registration_year=" + params.registration_year + "&registration_month=" + params.registration_month + "&vehicle_mileage=" + params.vehicle_mileage
      + "&max_annual_mileage=" + params.max_annual_mileage + "&term=" + params.term;
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers });
  }

  postCustomer(payload: any, vehicleType: string) {
    let accNo = this.datastoreService.getAccountNo();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.proposalApi + '/' + accNo + '/' + apiURI.creditcheck + '?' + apiURI.vehicleType + vehicleType; 
    let headers = getHeaders(true, '', false);
    return this.httpClient.post<any>(apiURL, payload, { headers });
  }

  getDecision(proposalId: any, palisCustomerId: any, vehicleType: string) {
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.proposalApi + '/' + apiURI.decision + '?proposal_id=' + proposalId + '&palis_customer_id=' + palisCustomerId + '&' + apiURI.vehicleType + vehicleType;
    let headers = getHeaders(true, '', false);
    return this.httpClient.get<any>(apiURL, { headers })
  }

  sendComplaintFulfilmentEmail(complaintType: string, complaintsRecipientEmailAddress: string, complaintsEmailAddress: string): Observable<any> {
    let companyNumber = getCompanyNumber();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.emailURI;
    let headerData = {
      customerId: this.datastoreService.getCustomerId,
      companyNumber: companyNumber,
    };
    let payLoadBody = this.requestService.getComplaintsFormData(complaintType, complaintsRecipientEmailAddress, complaintsEmailAddress);
    let headers = getHeadersPayment(true, headerData, false);
    return this.httpClient.post<any>(apiURL, payLoadBody, { headers });
  }

  sendComplaintAcknowledgementEmail(complaintType: string, complaintsEmailAddress: string, complaintsRecipientEmailAddress: string): Observable<any> {
    let companyNumber = getCompanyNumber();
    let apiURL: string = apiURI.baseURI + apiURI.accURI + apiURI.emailURI;
    let headerData = {
      customerId: this.datastoreService.getCustomerId,
      companyNumber: companyNumber,
    };
    let payLoadBody = this.requestService.getComplaintsAcknowledgementData(complaintType, complaintsEmailAddress, complaintsRecipientEmailAddress);
    let headers = getHeadersPayment(true, headerData, false);
    return this.httpClient.post<any>(apiURL, payLoadBody, { headers });
  }
}
